import React from "react";
import { Box, TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $suggestions } from "store/suggestions/suggestions";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";

const NewCategoryModal = observer(() => {
  const [name, setName] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    $suggestions
      .createCategory(name)
      .then(() => {
        $suggestions.fetchCategories();
        $modals.close();
      })
      .catch((err) => console.log(err.response))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Создание категории">
      <form onSubmit={onSubmit}>
        <TextField
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Имя категории"
          variant="outlined"
        />

        <Box sx={{ textAlign: "right" }}>
          <LoadingButton loading={btnLoading} type="submit" variant="contained">
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default NewCategoryModal;
