import { TableContainer } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { $spinner } from "store/spinner";
import { debounce } from "lodash";

const tableWrapperStyles = {
  background: "#FFF",
  padding: "0 12px",
  borderRadius: "12px",
};

export const InfiniteScroll = observer(
  ({ onScroll, children, hasMore, isTable = true, style }) => {
    const tableEl = useRef();

    const scrollListener = () => {
      if (!hasMore) return;
      let bottom =
        tableEl.current.scrollHeight - tableEl.current.clientHeight - 500;

      if (tableEl.current.scrollTop >= bottom && !$spinner.visible) {
        debouncedHandleScroll();
      }
    };

    const debouncedHandleScroll = debounce(() => onScroll(), 300);

    useEffect(() => {
      const tableRef = tableEl.current;
      tableRef.addEventListener("scroll", scrollListener);
      return () => {
        tableRef.removeEventListener("scroll", scrollListener);
      };
    }, [scrollListener]);

    return (
      <TableContainer
        style={style}
        sx={{ ...(isTable && tableWrapperStyles) }}
        ref={tableEl}
      >
        {children}
      </TableContainer>
    );
  }
);
