export const ORDER_STATUS_DELETED = 0;
export const ORDER_STATUS_NEW = 1;
export const ORDER_STATUS_WAITING = 2;
export const ORDER_STATUS_COLLECT = 3;
export const ORDER_STATUS_SHIPPED = 4;
export const ORDER_STATUS_DELIVERED = 5;
export const ORDER_STATUS_ISSUED = 6;
export const ORDER_STATUS_MODERATION = 7;
export const ORDER_STATUS_PAYMENT_REQUIRED = 8;

export const PAYMENT_TYPE_CASH = 0;
export const PAYMENT_TYPE_ONLINE_CARD = 1;
export const PAYMENT_TYPE_INVOICE = 2;
export const PAYMENT_TYPE_APPLE_PAY = 3;
export const PAYMENT_TYPE_GOOGLE_PAY = 4;
export const PAYMENT_TYPE_SBP = 5;

export const paymentTypeStatuses = {
  [PAYMENT_TYPE_CASH]: "оплата при получении",
  [PAYMENT_TYPE_ONLINE_CARD]: "онлайн оплата",
  [PAYMENT_TYPE_INVOICE]: "безналичная оплата",
  [PAYMENT_TYPE_APPLE_PAY]: "Apple Pay",
  [PAYMENT_TYPE_GOOGLE_PAY]: "Google Pay",
  [PAYMENT_TYPE_SBP]: "система быстрых платежей",
};

export const orderStatuses = {
  [ORDER_STATUS_DELETED]: "удален",
  [ORDER_STATUS_NEW]: "новый",
  [ORDER_STATUS_WAITING]: "ожидает сборки",
  [ORDER_STATUS_COLLECT]: "на сборке",
  [ORDER_STATUS_SHIPPED]: "в пути",
  [ORDER_STATUS_DELIVERED]: "в ПВЗ",
  [ORDER_STATUS_ISSUED]: "выдан",
  [ORDER_STATUS_MODERATION]: "на модерации",
  [ORDER_STATUS_PAYMENT_REQUIRED]: "ожидает оплаты",
};

export const ONLINE_PAYMENT_ALLOWED_ORDER_STATUS = [
  ORDER_STATUS_NEW,
  ORDER_STATUS_COLLECT,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_MODERATION,
  ORDER_STATUS_PAYMENT_REQUIRED,
];

export const PAYMENT_STATUS_NOT_PAID = 0;
export const PAYMENT_STATUS_WAITING_PAYMENT = 1;
export const PAYMENT_STATUS_PAID = 2;
export const PAYMENT_STATUS_BILLED = 3;

export const ONLINE_PAYMENT_ALLOWED_PAYMENT_STATUS = [
  PAYMENT_STATUS_NOT_PAID,
  PAYMENT_STATUS_WAITING_PAYMENT,
];

export const paymentStatuses = {
  [PAYMENT_STATUS_NOT_PAID]: "не оплачено",
  [PAYMENT_STATUS_WAITING_PAYMENT]: "транзакция сформирована",
  [PAYMENT_STATUS_PAID]: "оплачено",
  [PAYMENT_STATUS_BILLED]: "счет сформирован",
};

export const allowedOnlinePayment = (order) => {
  return (
    ONLINE_PAYMENT_ALLOWED_ORDER_STATUS.includes(order.status) &&
    ONLINE_PAYMENT_ALLOWED_PAYMENT_STATUS.includes(order.paid)
  );
};

export const checkEntityPayment = (order) => {
  return order.billing && order.paid === 1;
};
