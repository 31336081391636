import styled from "@emotion/styled";
import React from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";

const HeaderDiv = styled.div`
  min-height: 49px;
  border-bottom: 1px solid #e7e8ea;
  padding: 4px 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  .icon {
    color: #3b3b3b;
  }

  .user-name {
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    display: flex;
    text-decoration: underline;
    text-decoration-style: dotted;
    color: #207bcb;
    margin-left: 8px;
  }
`;

export const ChatMessagesHeader = observer(
  ({ children, backArrowClicked, hideBackArrow }) => {

    return (
      <HeaderDiv>
        <div className="flex">
          {!hideBackArrow && (
            <div>
              <IconButton
                sx={{ marginTop: "2px" }}
                onClick={backArrowClicked}
                edge="start"
                color="inherit"
              >
                <ArrowBackIosNewOutlinedIcon
                  style={{ width: "20px", height: "20px" }}
                  className="icon"
                />
              </IconButton>
            </div>
          )}
        </div>
        {children}
      </HeaderDiv>
    );
  }
);
