import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Modal } from "ui-components/modal";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InputMask from "react-input-mask";
import { LoadingButton } from "@mui/lab";
import { $modals } from "store/modals";
import { $roles } from "store/roles/roles";
import { $users } from "store/users/users";
import Chip from "@mui/material/Chip";
import { SelectSt } from "./createModal";
import { $user } from "../../../store/user";
import { $permissions } from "../../../store/permissions/permissions";

const EditUserModal = observer(() => {
  const user = $modals.params;

  const [name, setName] = useState(user.profile?.name);
  const [email, setEmail] = useState(user.email ? user.email : "");
  const [phone, setPhone] = useState(user.phone);
  const [roles, setRoles] = useState(user.roles.map((el) => el.item_name));
  const [password, setPassword] = useState("");
  const [sendPasswordToEmail, setSendPasswordToEmail] = useState(false);

  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    $roles.fetchRoles({ hideSpinner: true });
  }, []);

  useEffect(() => {
    setSendPasswordToEmail(password.length > 0 && email.length > 0);
  }, [password, email]);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setErrors({});
    $users
      .edit({
        id: user.id,
        name: name,
        email: email,
        phone: phone.replace("+7", "").replaceAll(" ", ""),
        role: roles,
        sendPasswordToEmail: sendPasswordToEmail,
        ...(password && { password: password }),
      })
      .then(() => {
        $users.fetchList();

        if (user.id === $user.profile.user_id) {
          $permissions.initPermissions();
        }

        $modals.close();
      })
      .catch((err) => {
        if (err.response.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal title="Редактирование пользователя">
      <form onSubmit={onSubmit}>
        <TextField
          defaultValue={name}
          error={!!errors.name}
          helperText={errors.name}
          required
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Имя"
          variant="outlined"
        />

        <TextField
          defaultValue={email}
          error={!!errors.email}
          helperText={errors.email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="E-mail"
          variant="outlined"
          type="email"
        />

        {phone && (
          <InputMask
            defaultValue={phone}
            maskChar=" "
            permanents={[0]}
            onChange={(e) => setPhone(e.target.value)}
            mask="+7 999 999 99 99"
          >
            {() => (
              <TextField
                error={!!errors.phone}
                helperText={errors.phone}
                required
                fullWidth
                sx={{ mb: 3 }}
                label="Телефон"
                variant="outlined"
              />
            )}
          </InputMask>
        )}

        <FormControl sx={{ mb: 3 }} error={!!errors.role} fullWidth>
          <InputLabel required id="roles">
            Роли
          </InputLabel>
          <SelectSt
            error={!!errors.role}
            required
            labelId="roles"
            id="roles-select"
            multiple
            value={roles}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value, key) => (
                  <Chip sx={{ p: 0 }} key={key} label={value} />
                ))}
              </Box>
            )}
          >
            {$roles.roles.map((el) => (
              <MenuItem
                disabled={el.name === "user"}
                key={el.name}
                value={el.name}
              >
                {el.name}
              </MenuItem>
            ))}
          </SelectSt>
          <FormHelperText>{errors.role}</FormHelperText>
        </FormControl>

        <TextField
          error={!!errors.password}
          helperText={errors.password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          label="пароль (необязательно)"
          variant="outlined"
        />

        <FormGroup sx={{ mb: 3 }}>
          <FormControlLabel
            disabled={!password || email.length < 1}
            control={
              <Checkbox
                key={sendPasswordToEmail}
                onChange={(e) => setSendPasswordToEmail(e.target.checked)}
                value={sendPasswordToEmail}
              />
            }
            label="Отправить пароль на почту"
          />
        </FormGroup>

        <LoadingButton
          sx={{ float: "right" }}
          type="submit"
          loading={btnLoading}
          variant="contained"
        >
          Сохранить
        </LoadingButton>
      </form>
    </Modal>
  );
});

export default EditUserModal;
