import React from "react";
import { Box, TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { $tags } from "store/tags/tags";
import { LoadingButton } from "@mui/lab";

const NewTagModal = observer(() => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setError(null);

    $tags
      .addNewTag(name)
      .then((res) => {
        $tags.fetchTagsList("fetchAll");
        $modals.close();
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data[0].message);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Создать группу">
      <form onSubmit={onSubmit}>
        <TextField
          required
          error={!!error}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Название"
          variant="outlined"
          helperText={error}
        />
        <Box sx={{ textAlign: "right" }}>
          <LoadingButton type="submit" variant="contained" loading={btnLoading}>
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default NewTagModal;
