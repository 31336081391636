import React from "react";
import { types } from "mobx-state-tree";
import { api } from "../../api";
import { flow } from "mobx";
import { USER_STATUS_ACTIVE } from "lib/userStatuses";
import { $snackbar } from "../snackbar";
import { $customers } from "./customers";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const TYPE_BLOCKED = 1;
const TYPE_UNBLOCKED = 2;

export const autoBlockTypesNames = {
  [TYPE_BLOCKED]: "Автоматическая блокировка",
  [TYPE_UNBLOCKED]: "Автоматическая разблокировка",
};

export const operatorBlockTypesNames = {
  [TYPE_BLOCKED]: "Заблокирован оператором",
  [TYPE_UNBLOCKED]: "Разблокирован оператором",
};

export const typeIcons = {
  [TYPE_BLOCKED]: <BlockIcon color="error" />,
  [TYPE_UNBLOCKED]: <CheckCircleOutlineIcon color="success" />,
};

export const $customerActions = types
  .model("CustomerActions", {
    blockHistory: types.frozen([]),
  })
  .actions((self) => ({
    delete: flow(function* (id) {
      try {
        yield api()
          .delete(`admin/users/${id}`)
          .then((res) => res.data);
        $customers.fetchList();
      } catch (err) {
        $snackbar.show("Ошибка удаления");
        throw err;
      }
    }),
    restore: flow(function* (id) {
      try {
        yield api()
          .post(`admin/users/${id}`)
          .then((res) => res.data);
        $customers.fetchList();
      } catch (err) {
        $snackbar.show("Ошибка восстановления");
        throw err;
      }
    }),
    handleBlock: flow(function* (user, comment) {
      try {
        yield api()
          .post(`admin/users/blocked`, {
            user_id: user.id,
            comment: comment,
            type:
              user.status === USER_STATUS_ACTIVE
                ? TYPE_BLOCKED
                : TYPE_UNBLOCKED,
          })
          .then((res) => res.data);
      } catch (err) {
        $snackbar.show("Ошибка блокировки");
        throw err;
      }
    }),
    fetchBlockHistory: flow(function* (id) {
      self.setBlockHistory([]);
      const res = yield api()
        .get(`admin/users/${id}/block-history`)
        .then((res) => res.data);
      self.setBlockHistory(res);
    }),
    setBlockHistory(data) {
      self.blockHistory = data;
    },
  }))
  .create();
