import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { $user } from "store/user";
import { useEffect, useState } from "react";
import logo from "assets/tbp_logo.svg";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import InputMask from "react-input-mask";

const Login = observer(() => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    setErrors({});
    e.preventDefault();
    $user
      .login(phone.replace(/\D/g, "").substring(1), password)
      .then(() => navigate({ pathname: "/" }))
      .catch((err) => {
        if (err.response.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  useEffect(() => {
    if ($user.isAuthorized) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <Grid
      sx={{ height: "100vh", background: "#e3f2fd" }}
      container
      direction="column"
      justify="center"
      alignItems="stretch"
    >
      <Box
        sx={{
          mx: "auto",
          width: isMobile ? "94%" : 398,
          my: "auto",
          background: "#fff",
          padding: "24px",
          borderRadius: "8px",
          border: "1px solid rgba(144, 202, 249, 0.46)",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <img width={150} src={logo} alt="" />
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl
            required
            fullWidth
            error={errors.password || errors.phone}
            sx={{ ...theme.typography.customInput }}
          >
            <InputLabel>Телефон</InputLabel>
            <InputMask
              maskChar=" "
              permanents={[0]}
              onChange={(e) => setPhone(e.target.value)}
              mask="+7 999 999 99 99"
            >
              {() => <OutlinedInput autoFocus type="text" />}
            </InputMask>
          </FormControl>

          <FormControl
            required
            fullWidth
            error={errors.password}
            sx={{ ...theme.typography.customInput }}
          >
            <InputLabel htmlFor="outlined-adornment-password-login">
              Пароль
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-login"
              type={passwordVisible ? "text" : "password"}
              name="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    edge="end"
                    size="large"
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText>{errors.password}</FormHelperText>
          </FormControl>
          {/*<Stack direction="row" justifyContent="flex-end" spacing={1}>*/}
          {/*  <Typography*/}
          {/*    onClick={() => {*/}
          {/*      alert(":(");*/}
          {/*    }}*/}
          {/*    variant="subtitle1"*/}
          {/*    color="secondary"*/}
          {/*    sx={{ textDecoration: "none", cursor: "pointer" }}*/}
          {/*  >*/}
          {/*    Забыли пароль?*/}
          {/*  </Typography>*/}
          {/*</Stack>*/}
          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Войти
            </Button>
          </Box>
        </form>
      </Box>
    </Grid>
  );
});

export default Login;
