import React from "react";
import { TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { $modals } from "store/modals";
import { LoadingButton } from "@mui/lab";
import { $roles } from "store/roles/roles";

const EditRoleModal = observer(() => {
  const role = $modals.params;

  const [name, setName] = useState(role.name);
  const [description, setDescription] = useState(
    role.description ? role.description : ""
  );
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setErrors({});
    $roles
      .editRole(role.name, {
        name: name,
        description: description,
      })
      .then(() => {
        $roles.fetchRoles();
        $modals.close();
      })
      .catch((err) => {
        if (err.response.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Редактирование роли">
      <form onSubmit={onSubmit}>
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          error={!!errors.name}
          helperText={errors.name}
          fullWidth
          sx={{ mb: 3 }}
          label="Роль*"
          variant="outlined"
        />

        <TextField
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          error={!!errors.description}
          helperText={errors.description}
          fullWidth
          sx={{ mb: 3 }}
          label="Описание"
          variant="outlined"
        />

        <LoadingButton
          sx={{ float: "right" }}
          type="submit"
          loading={btnLoading}
          variant="contained"
          onClick={onSubmit}
        >
          Сохранить
        </LoadingButton>
      </form>
    </Modal>
  );
});

export default EditRoleModal;
