import { types } from "mobx-state-tree";
import { isMobile } from "react-device-detect";

const sidebarVisible = localStorage.getItem("sidebarVisible") === "true";

export const $sidebar = types
  .model({
    visible: types.optional(types.boolean, isMobile ? false : sidebarVisible),
    pageTitle: types.optional(types.string, ""),
  })
  .actions((self) => ({
    toggle() {
      self.visible = !self.visible;
      localStorage.setItem("sidebarVisible", self.visible ? "true" : "false");
    },
    hide() {
      self.visible = false;
    },
    setPageTitle(value) {
      self.pageTitle = value;
      document.title = `ТБП | ${value}`;
    },
  }))
  .create();
