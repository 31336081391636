import { observer } from "mobx-react-lite";
import { $notificationForm } from "../../store/notifications/notificationForm";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import React from "react";

export const CustomSelect = observer(
  ({ label, options, selectedOptions, onChange }) => {
    const reloadCount = () => {
      setTimeout(() => $notificationForm.calculateUsersCount(), 500);
    };

    return (
      <Autocomplete
        disabled={$notificationForm.filtersLoading}
        onClose={reloadCount}
        onInputChange={(e) => {
          if (e.target.toString() === "[object SVGSVGElement]") {
            reloadCount();
          }
        }}
        multiple
        limitTags={1}
        options={options}
        onChange={(e, newValue) => onChange(newValue)}
        disableCloseOnSelect
        getOptionLabel={(option) =>
          label === "ПВЗ" ? `${option.id} | ${option.address}` : option.name
        }
        renderTags={() => <div>Выбрано: {selectedOptions.length}</div>}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginLeft: "-14px" }} checked={selected} />
            {label === "ПВЗ" ? `${option.id} | ${option.address}` : option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={label} />
        )}
      />
    );
  }
);
