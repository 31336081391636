import { IconButton, Tooltip } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import React from "react";

export const FilePreview = ({ onDelete, uploadedFileName }) => {
  return (
    <div style={{ padding: "0 16px", marginBottom: "10px", display: "flex" }}>
      <div
        style={{
          position: "relative",
          background: "rgba(0,0,0,.1)",
          padding: "2px 7px",
          borderRadius: "5px",
        }}
      >
        <Tooltip placement="top" title="Не прикреплять">
          <IconButton
            size="small"
            onClick={onDelete}
            style={{
              position: "absolute",
              right: "-26px",
              background: "rgba(77,76,76,0.6)",
              borderRadius: "2px",
              width: "20px",
              height: "20px",
            }}
          >
            <ClearOutlinedIcon style={{ color: "#fff", width: "16px" }} />
          </IconButton>
        </Tooltip>
        <div style={{ opacity: "0.8" }}>{uploadedFileName}</div>
      </div>
    </div>
  );
};
