import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "../api";
import { flow } from "mobx";
import { $spinner } from "./spinner";
import { union } from "lodash";

export const $complaints = types
  .model({
    date: types.frozen(null),
    complaintsMessages: types.frozen([]),
    stats: types.frozen([]),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get chartCategories() {
      // console.log(self.complaintsMessages);
      return self.stats.map((el) => el.date);
    },
    get chartSeries() {
      // console.log(self.complaintsMessages);
      return self.stats.map((el) => el.count);
    },
  }))
  .actions((self) => ({
    fetchComplaintsList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setComplaints([]);
      }
      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`consultant/complaints`, {
            params: {
              date: self.date,
              limit: self.limit,
              offset: self.offset,
            },
          })
          .then((res) => res.data);
        self.setHasMore(res.complaints.length >= self.limit);

        self.setComplaints(
          type === "paginate"
            ? union(self.complaintsMessages, res.complaints)
            : res.complaints
        );
        self.setStats(res.stats);
      } catch (err) {
        console.error("fetch complaints list: ", err);
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    setComplaints(value) {
      self.complaintsMessages = value;
    },
    setStats(value) {
      self.stats = value;
    },
    setDate(value) {
      self.date = value;
      self.fetchComplaintsList();
    },
    paginate() {
      self.offset += self.limit;
      self.fetchComplaintsList("paginate");
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLimit(data) {
      self.limit = data;
    },
  }))
  .create();
