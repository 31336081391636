import React from "react";
import { Box, FormControl, TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { Alert, LoadingButton } from "@mui/lab";
import { $chatOrders } from "store/chat/orders";
import { $bitrix } from "store/bitrix";
import iziToast from "izitoast";
import Barcode from "react-jsbarcode";

const ReturnProductFromOrderModal = observer(() => {
  const product = $modals.params.product;
  const orderId = product.pivot.order_id;
  const shopId = $modals.params.shopId;
  const barcode = $modals.params.orderBarcode;

  const [message, setMessage] = useState(
    `Здравствуйте, покупателю заказа №${orderId} разрешен возврат на "${product.name}" в количестве ${product.pivot.amount} шт. сразу после выкупа заказа.`
  );

  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const svgElement = document.getElementById("barcode");
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgString], { type: "image/svg+xml" });
    const formData = new FormData();
    formData.append("attach", blob, "my-svg-file.svg");
    formData.append("message", message);
    formData.append("shop_id", shopId);

    $chatOrders
      .returnProductFromOrder(formData, orderId)
      .then(() => {
        $modals.close();
        iziToast.success({
          message: "Сообщение отправлено",
        });
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Возврат товара" subheader={product.name}>
      {!$bitrix.isAuthenticated ? (
        <Alert severity="error">
          <span
            style={{
              color: "#206491",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => $bitrix.authClicked()}
          >
            Авторизуйтесь
          </span>{" "}
          через Битрикс24 для продолжения.
        </Alert>
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                helperText="Сообщение и ссылка на ШК будет отправлено магазину в Битрикс24."
                label="Сообщение"
                rows={4}
                multiline
                required
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </FormControl>

            {barcode.length && (
              <div id="barcode">
                <Barcode
                  value={barcode}
                  options={{ format: "code128" }}
                  renderer={"svg"}
                />
              </div>
            )}

            <Box sx={{ textAlign: "right" }}>
              <LoadingButton
                loading={btnLoading}
                type="submit"
                variant="contained"
              >
                Отправить
              </LoadingButton>
            </Box>
          </form>
        </>
      )}
    </Modal>
  );
});

export default ReturnProductFromOrderModal;
