import {Avatar, Box, Button, Grid, IconButton, Tooltip} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import {format, parseISO} from "date-fns";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import {parseUnixTimeDate} from "lib/features";
import {isDesktop, isMobile} from "react-device-detect";
import {
  checkEntityPayment,
  ORDER_STATUS_DELETED,
  ORDER_STATUS_ISSUED,
  PAYMENT_STATUS_WAITING_PAYMENT,
} from "lib/orderStatuses";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import {CHECK_ENTITY_PAYMENT_MODAL} from "ui-components/modal/modals";
import {$modals} from "store/modals";
import {
  ChipStyled,
  DeliveryChip,
  EntityChip,
  OrderStatusChip,
  PaymentStatusChip,
  PaymentTypeChip,
} from "./orderChips";
import {LoadingButton} from "@mui/lab";
import {ReturnProductButton} from "./returnProductButton";

const OrderCardSt = styled.div`
    background: #fff;
    margin-bottom: 12px;
    border-radius: 4px;

    .header {
        font-weight: 400;
        font-size: 16px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;

        .text-muted {
            font-size: 14px;
            font-weight: 400;
            color: #838383;

            span {
                color: #000;
            }
        }

        .action-buttons {
            text-align: ${isMobile ? "left" : "right"};
            padding-top: 6px;
        }
    }

    .order-list-row-wrapper {
        padding: 10px;
        border-bottom: 1px solid #e7e8ea;

        .comment-label {
            font-size: 14px;
            color: #0d3fe3;
            margin-top: 4px;
        }

        .cheaper-label {
            font-size: 14px;
            color: #e3870d;
            margin-top: 4px;
        }

        .order-list-row {
            display: flex;
            //margin-bottom: 10px;

            &__item {
                padding: 9px 0 9px 14px;
                min-width: 90px;
                color: rgba(0, 0, 0, 0.7);
                text-align: right;
                font-size: 15px;

                &.name {
                    flex: 1 1 auto;
                    padding: 0 0 0 14px;
                    text-align: left;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    a {
                        word-break: break-word;
                    }
                }

                &.sum {
                    margin-left: auto;
                }

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }

    .footer {
        padding: 12px;
        display: flex;
        justify-content: space-between;

        span {
            font-size: 14px;
            color: #9a9a9a;
        }

        .total {
            text-align: ${isMobile ? "right" : "left"};
            font-size: 16px;
            font-weight: 400;
        }
    }
`;

const printDeliveryInfo = (order) => {
  if (order.handed_at) {
    return (
      <>
        Выдан <span>{parseUnixTimeDate(order.handed_at)}</span>
      </>
    );
  } else if (order.shipped_at) {
    return (
      <>
        Ожидаемая дата доставки{" "}
        <span>{parseUnixTimeDate(order.shipped_at)}</span>
      </>
    );
  } else {
    return "";
  }
};

export const OrderCard = ({
                            order,
                            onCancelClicked,
                            onRestoreOrderClicked,
                            cancelBtnLoading,
                          }) => {

  const calcOrderPrice = (products) => {
    let sum = 0;
    products.forEach(el => {
      sum += parseFloat(el.pivot.old_amount * el.pivot.price);
    });
    return sum.toFixed(2);
  };

  return (
    <OrderCardSt>
      <div className="header">
        <Grid container>
          <Grid item xs={12} md={9}>
            <div style={{marginBottom:'4px'}}>
              <span style={{fontWeight: 500}}>№{order.id}</span>
              {" | "}{order.created_at}
            </div>
            <div style={{padding: "4px 0 0 0"}}>
              {order.payment_system &&
                <ChipStyled
                  sx={{
                    background: "#00ffd7",
                    color: "#000",
                  }}
                  label={order.payment_system}
                />}
              <OrderStatusChip status={order.status}/>
              <PaymentStatusChip
                paymentType={order.payment_type}
                status={order.paid}
              />
              <PaymentTypeChip paymentType={order.payment_type}/>
              {order.billing && <EntityChip/>}
              {order.delivery_request == 1 && <DeliveryChip/>}
            </div>
            {order.billing &&
              order.paid === PAYMENT_STATUS_WAITING_PAYMENT && (
                <div className="text-muted">Счет сформирован</div>
              )}
            <div className="text-muted">{order.store.address}</div>
            <div className="text-muted">{printDeliveryInfo(order)}</div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="action-buttons">
              {order.status == ORDER_STATUS_DELETED ? (
                <Button
                  onClick={() => onRestoreOrderClicked(order.id, order.status)}
                  size="small"
                  variant="outlined"
                >
                  Восстановить
                </Button>
              ) : (
                <>
                  {/*<Button disabled size="small" variant="outlined">*/}
                  {/*  Сменить тип оплаты*/}
                  {/*</Button>*/}
                  {order.status !== ORDER_STATUS_ISSUED && (
                    <LoadingButton
                      sx={{ml: 1}}
                      loading={cancelBtnLoading}
                      onClick={() => onCancelClicked(order.id)}
                      color="error"
                      size="small"
                      variant="outlined"
                    >
                      Отменить
                    </LoadingButton>
                  )}
                </>
              )}
            </div>

            <Box sx={{mt: 1}} className="flex-end">
              {/*{allowedOnlinePayment(order) && (*/}
              {/*  <Tooltip title="Проверить оплату">*/}
              {/*    <IconButton*/}
              {/*      onClick={() => $modals.show(CHECK_PAYMENT_MODAL, order)}*/}
              {/*    >*/}
              {/*      <ContentPasteSearchOutlinedIcon />*/}
              {/*    </IconButton>*/}
              {/*  </Tooltip>*/}
              {/*)}*/}
              {checkEntityPayment(order) && (
                <Tooltip title="Проверить оплату юр. лица">
                  <IconButton
                    onClick={() =>
                      $modals.show(CHECK_ENTITY_PAYMENT_MODAL, order)
                    }
                  >
                    <ContentPasteSearchOutlinedIcon/>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>

      {order.products.map((product, key) => {
        return (
          <div key={key} className="order-list-row-wrapper">
            <div className="order-list-row">
              <a
                target="_blank"
                href={`https://tovarbezpereplat.ru/products/${product.id}`}
              >
                <Avatar
                  src={product.images?.url}
                  sx={{borderRadius: "2px"}}
                  variant="square"
                >
                  <CameraAltOutlinedIcon/>
                </Avatar>
              </a>
              <div className="order-list-row__item name">
                <a
                  target="_blank"
                  href={`https://tovarbezpereplat.ru/products/${product.id}`}
                >
                  {product.name}
                </a>
              </div>

              {isDesktop && (
                <>
                  <div
                    className="order-list-row__item"
                    style={{marginLeft: "auto"}}
                  >
                    {product.pivot.amount} шт.
                  </div>
                  <div className="order-list-row__item">
                    {product.pivot.price} ₽
                  </div>
                </>
              )}
              <div className="order-list-row__item sum">
                {parseFloat(product.pivot.price * product.pivot.amount).toFixed(
                  2
                )}{" "}
                ₽
              </div>
              <div>
                <ReturnProductButton
                  product={product}
                  orderBarcode={order.external_id}
                  storeId={order.store_id}
                  status={order.status}
                />
              </div>
            </div>
            {product.pivot.comment && (
              <div className="comment-label">
                Комментарий:{" "}
                <span style={{color: "#000"}}> {product.pivot.comment}</span>
              </div>
            )}
            {product.cheaper_count > 0 && (
              <div className="cheaper-label">
                Подтвердите или отклоните товар в сервисе “Нашел дешевле”
              </div>
            )}
          </div>
        );
      })}

      <div className="footer">
        <span>
          Последнее обновление заказа:{" "}
          {order.updated_at}
        </span>
        <Tooltip title={`Изначальная сумма заказа: ${calcOrderPrice(order.products)} ₽`}>
          <div className="total">Итого: {order.price} ₽</div>
        </Tooltip>
      </div>
    </OrderCardSt>
  );
};
