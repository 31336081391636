import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { $user } from "../user";
import { api, v3Endpoint } from "api";
import { $spinner } from "../spinner";
import { appRoutes } from "../../routes";
import { find } from "lodash";
import iziToast from "izitoast";

export const $permissions = types
  .model({
    list: types.frozen([]),
    _initPermissions: types.frozen(null),
    permissions: types.frozen([]),
    selectedPage: types.optional(types.string, "dashboard"),
  })
  .actions((self) => ({
    initPermissions: flow(function* () {
      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`permissions/init`)
          .then((res) => res.data);

        self.setInitPermissions(res.allowed);
        if (res.allowed.length === 0) {
          iziToast.warning({
            message:
              "Таблица прав не заполнена! Приложение доступно только разработчику",
          });
        }
        $user.setIsDeveloper(res.is_developer);
        $user.setRoles(res.own_roles);
        $user.setProfile(res.profile);
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    fetchList: flow(function* () {
      try {
        $spinner.show();

        const res = yield api(v3Endpoint)
          .get(`permissions?page=${self.selectedPage}`)
          .then((res) => res.data);
        self.setPermissions(res);
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    togglePermission: flow(function* (id, action) {
      yield api(v3Endpoint).post("permissions/toggle", {
        id: id,
        action: action,
      });

      self.fetchList();
    }),
    generateCatalogPermissions: flow(function* () {
      try {
        $spinner.show();
        yield api(v3Endpoint).post("permissions/generate", {
          pages: appRoutes,
        });
        self.fetchList();
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    setList(data) {
      self.list = data;
    },
    setPermissions(data) {
      self.permissions = data;
    },
    setInitPermissions(data) {
      self._initPermissions = data;
    },
    setPage(value) {
      self.selectedPage = value;
    },
    isAllowedForRole(path) {
      path = path.replace("/", "");
      const permissionsForPath = self._initPermissions[path];
      if ($user.isDeveloper) return true;
      return !!find(permissionsForPath, ["view", 1]);
    },
  }))
  .create();
