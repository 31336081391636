import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ChatList } from "./chatList";
import { Messages } from "./messages";
import { CustomerInfo } from "./customerInfo/customerInfo";
import { isDesktop, isMobile } from "react-device-detect";
import styled from "@emotion/styled";
import { $suggestions } from "store/suggestions/suggestions";
import { $chatRooms } from "store/chat/rooms";
import { $chatMessages } from "store/chat/messages";

const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  background: #fff;
`;

const Chat = observer(() => {
  useEffect(() => {
    $chatRooms.fetchRooms();
    $suggestions.fetchCategories();
    $chatMessages.getUnreadCount();
  }, []);

  const showRooms = isDesktop || (isMobile && !$chatRooms.selectedCustomerId);
  const showConversation = true;

  const showCustomerInfo = isDesktop;

  return (
    <MainWrapper>
      {showRooms && <ChatList />}
      {showConversation && <Messages key={$chatRooms.selectedCustomerId} />}
      {showCustomerInfo && <CustomerInfo />}
    </MainWrapper>
  );
});

export default Chat;
