import React from "react";
import {
  Box,
  Button,
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {useState} from "react";
import {$suggestions} from "store/suggestions/suggestions";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";

const DeleteCategoryModal = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);
  const category = $modals.params

  const onSubmit = () => {
    setBtnLoading(true);
    $suggestions.deleteCategory(category.id)
      .then(() => {
        $suggestions.fetchCategories();
        $modals.close();
      })
      .catch(err => {
        console.log(err.response);
      })
      .finally(() => setBtnLoading(false));
  }

  return (
    <Modal title="Удаление категории"
           subheader={<>
             Вы действительно хотите удалить категорию <b>{category.name}</b> ?
           </>}
    >
      <Box sx={{textAlign: 'right'}}>
        <Button
          disabled={btnLoading}
          variant="contained"
          onClick={onSubmit}
        >
          Удалить
        </Button>
      </Box>
    </Modal>
  )
});

export default DeleteCategoryModal;