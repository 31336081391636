import Pusher from "pusher-js";
import sound from "./assets/notification_sound.mp3";
import { debounce } from "lodash";
import { $chatRooms } from "./store/chat/rooms";
import { $chatMessages } from "./store/chat/messages";
import config from "./config";
import { v3EndpointCommon } from "./api";

Pusher.logToConsole = true;

const pusher = new Pusher(config.PUSHER_KEY, {
  cluster: "eu",
  authEndpoint: `${v3EndpointCommon}/pusher-auth`,
});

const channelOperator = pusher.subscribe(`operator-chat`);
channelOperator.bind("new-message", function (data) {
  // if (!$user.isCourier) {
  processingMessages(data, "OPERATOR");
  // }
});

const channelDebug = pusher.subscribe(`private-dd`);
channelDebug.bind("new", function (data) {
  console.log(data);
});

// const channelCustomer = pusher.subscribe(`courier-chat`);
// channelCourier.bind("new-message", function (data) {
//   if ($user.isCourier) {
//     processingMessages(data, "COURIER");
//   }
// });

function processingMessages(data) {
  const chatRoom = data.chat_room;

  $chatMessages.getUnreadCount();
  $chatRooms.fetchRooms({ hiddenLoader: true, fromPusher: true });

  if (!chatRoom.message.operator_id) {
    debouncedPlaySound();
  }

  if ($chatRooms.selectedCustomerId == chatRoom.message.customer_id) {
    $chatMessages.appendMessagesByUser(chatRoom.message);
  }
}

const playSound = () => {
  const audio = new Audio(sound);
  audio.play();
};

const debouncedPlaySound = debounce(playSound, 500);
