import React, {useEffect, useRef} from "react";
import styled from "@emotion/styled";
import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {isMobile} from "react-device-detect";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import {Suggestions} from "./components/suggestions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {SpinnerWrapper} from "./chatList";
import {SpeechBubble} from "./components/speechMessage";
import {$chatMessages} from "store/chat/messages";
import {$chatRooms} from "store/chat/rooms";
import {useNavigate} from "react-router-dom";
import {$draft} from "store/chat/draft";
import {ChatMessagesHeader} from "./components/chatMessagesHeader";
import {ChatTextArea} from "./components/textarea/chatTextArea";
import {$textarea} from "store/chat/textarea";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import {$chatOrders} from "store/chat/orders";

const Container = styled.div`
    width: 37%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    box-sizing: border-box;
`;

export const EarlierMessagesBtn = styled.div`
    text-align: center;
    padding: 10px 0;
    background: rgb(226, 242, 253);
    cursor: pointer;
    transition: all .1s ease-in-out;
    margin-top: -14px;

    span {
        cursor: pointer;
        font-size: 15px;
        padding: 8px;
        opacity: 0.8;
        color: #207bcb;
    }

    &:hover {
        opacity: 0.7;
    }
`;

export const Messages = observer(() => {
  const chatRef = useRef(null);
  const navigate = useNavigate();
  const spinnerVisible = $chatMessages.loading;

  const messageClicked = (message) => {
    if ($textarea.isEditMode) return;
    $chatMessages.setSelectedMessage(
      $chatMessages.selectedMessage === message ? null : message
    );
  };

  const cancelEditing = () => {
    $chatMessages.setSelectedMessage(null);
    $textarea.setValue("");
    $textarea.setEditMode(false);
    $textarea.clearAttachment();
  };

  const handleCloseSelectedMessage = () => {
    $chatMessages.setSelectedMessage(null);
    $textarea.setEditMode(false);
    $textarea.clearAttachment();
  };

  const editMessageClicked = () => {
    $textarea.clearAttachment();
    $textarea.setValue($chatMessages.selectedMessage.message);
    if (
      $chatMessages.selectedMessage.attachment_url &&
      !$chatMessages.selectedMessage.as_file
    ) {
      $textarea.setPreviewUrl($chatMessages.selectedMessage.attachment_url);
    }
    $textarea.setEditMode(true);
  };

  const deleteMessageClicked = () => {
    $chatMessages
      .deleteMessage($chatMessages.selectedMessage.id)
      .then(handleCloseSelectedMessage);
  };

  const markAsComplaint = () => {
    $chatMessages
      .toggleMessageComplaint($chatMessages.selectedMessage.id)
      .then(() => handleCloseSelectedMessage());
  };

  const scrollToBottom = () => {
    const element = chatRef.current;
    element.scrollTop = element.scrollHeight;
  };

  useEffect(() => {
    $textarea.setEditMode(false);

    if ($chatMessages.messagesByUser.length > 0 && $chatMessages.offset === 0) {
      scrollToBottom();
    }
  }, [$chatMessages.messagesByUser.length, spinnerVisible]);

  const backArrowClicked = () => {
    navigate({pathname: `/chat`});
    $draft.create($chatMessages.textarea);
    $chatRooms.setSelectedCustomerId(null);
  };

  const hideNotificationsClicked = (e) => {
    $chatMessages.getMessagesByUser({
      hideNotifications: e.target.checked,
    });
  };

  const placeholder = (
    <>
      <div/>
      <div style={{textAlign: "center", color: "#bbbbbb", fontSize: "16px"}}>
        Выберите диалог
      </div>
      <div/>
    </>
  );

  const content = (
    <>
      <ChatMessagesHeader
        hideBackArrow={!isMobile}
        backArrowClicked={backArrowClicked}
      >
        {$chatMessages.selectedMessage ? (
          <>
            {$chatMessages.selectedMessage.operator_id ? (
              <>
                <div style={{marginLeft: "auto"}}>
                  <Tooltip title="Редактировать сообщение">
                    <IconButton
                      disabled={
                        $textarea.isEditMode ||
                        !$chatMessages.isMessageEditAvailable
                      }
                      onClick={editMessageClicked}
                    >
                      <EditOutlinedIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Удалить сообщение">
                    <IconButton
                      disabled={!$chatMessages.isMessageEditAvailable}
                      color="error"
                      onClick={deleteMessageClicked}
                    >
                      <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div style={{marginLeft: "auto"}}>
                <Tooltip
                  title={
                    $chatMessages.selectedMessage.is_complaint
                      ? "Удалить жалобу"
                      : "Пометить как жалобу"
                  }
                >
                  <IconButton
                    style={{
                      color: $chatMessages.selectedMessage.is_complaint
                        ? "rgba(245, 2, 2, 0.63)"
                        : "",
                    }}
                    onClick={markAsComplaint}
                  >
                    <ReportGmailerrorredOutlinedIcon/>
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </>
        ) : (
          <>
            <FormControlLabel
              sx={{ml: "auto"}}
              control={<Switch onChange={hideNotificationsClicked}/>}
              label="скрыть уведомления"
            />
            <Tooltip title="Приоритетный диалог">
              <div>
                <IconButton onClick={() => $chatOrders.toggleImportant()}>
                  <LocalFireDepartmentIcon
                    sx={{
                      color: $chatOrders.isImportant ? "#ffa500" : "#b4b4b4",
                    }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          </>
        )}
      </ChatMessagesHeader>

      {spinnerVisible && (
        <SpinnerWrapper>
          <CircularProgress/>
        </SpinnerWrapper>
      )}

      <div
        ref={chatRef}
        style={{
          marginTop: "auto",
          overflowY: "scroll",
          paddingBottom: "5px",
        }}
      >
        {$chatMessages.hasMore && !spinnerVisible && (
          <EarlierMessagesBtn>
            <span onClick={() => $chatMessages.paginate()}>
              Загрузить более ранние сообщения
            </span>
          </EarlierMessagesBtn>
        )}

        {!spinnerVisible &&
          $chatMessages.messagesByUser.map((message, key) => (
            <SpeechBubble
              key={key}
              selectedMessage={$chatMessages.selectedMessage}
              messageClicked={messageClicked}
              message={message}
            />
          ))}
      </div>

      <div>
        <Suggestions/>
        <ChatTextArea
          cancelEditing={cancelEditing}
          selectedMessageId={$chatMessages.selectedMessage?.id}
          closeSelectedMessage={handleCloseSelectedMessage}
        />
      </div>
    </>
  );

  return (
    <Container>
      {$chatRooms.selectedCustomerId ? content : placeholder}
    </Container>
  );
});
