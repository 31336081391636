import React from "react";
import { TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { $modals } from "store/modals";
import { $roles } from "store/roles/roles";
import { LoadingButton } from "@mui/lab";

const CreateRoleModal = observer(() => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setErrors({});
    $roles
      .createRole({
        name: name,
        description: description,
      })
      .then(() => {
        $roles.fetchRoles();
        $modals.close();
      })
      .catch((err) => {
        if (err.response.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Создание роли">
      <form onSubmit={onSubmit}>
        <TextField
          required
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
          fullWidth
          sx={{ mb: 3 }}
          label="Роль"
          variant="outlined"
        />

        <TextField
          required
          onChange={(e) => setDescription(e.target.value)}
          error={!!errors.description}
          helperText={errors.description}
          fullWidth
          sx={{ mb: 3 }}
          label="Описание"
          variant="outlined"
        />

        <LoadingButton
          sx={{ float: "right" }}
          type="submit"
          loading={btnLoading}
          variant="contained"
        >
          Сохранить
        </LoadingButton>
      </form>
    </Modal>
  );
});

export default CreateRoleModal;
