import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
  OutlinedInput,
  Box,
  FormHelperText,
} from "@mui/material";
import { Modal } from "ui-components/modal";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InputMask from "react-input-mask";
import { LoadingButton } from "@mui/lab";
import { $roles } from "store/roles/roles";
import Chip from "@mui/material/Chip";
import styled from "@emotion/styled";
import { $users } from "store/users/users";
import { $modals } from "store/modals";

export const SelectSt = styled(Select)`
  .MuiSelect-select {
    padding: 10px;
  }

  #roles-select {
    min-height: 32px;
  }
`;

const CreateUserModal = observer(() => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    $roles.fetchRoles({ hideSpinner: true });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setErrors({});
    $users
      .create({
        name: name,
        email: email,
        phone: phone.replace("+7", "").replaceAll(" ", ""),
        role: roles,
      })
      .then(() => {
        $users.fetchList();
        $modals.close();
      })
      .catch((err) => {
        if (err.response.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  const [roles, setRoles] = useState(["user"]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal title="Создание пользователя">
      <form onSubmit={onSubmit}>
        <TextField
          error={!!errors.name}
          helperText={errors.name}
          required
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Имя"
          variant="outlined"
        />

        <TextField
          error={!!errors.email}
          helperText={errors.email}
          required
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="E-mail"
          variant="outlined"
          type="email"
        />

        <InputMask
          maskChar=" "
          permanents={[0]}
          onChange={(e) => setPhone(e.target.value)}
          mask="+7 999 999 99 99"
        >
          {() => (
            <TextField
              error={!!errors.phone}
              helperText={errors.phone}
              required
              fullWidth
              sx={{ mb: 3 }}
              label="Телефон"
              variant="outlined"
            />
          )}
        </InputMask>

        <FormControl sx={{ mb: 3 }} error={!!errors.role} fullWidth>
          <InputLabel required id="roles">
            Роли
          </InputLabel>
          <SelectSt
            error={!!errors.role}
            required
            labelId="roles"
            id="roles-select"
            multiple
            value={roles}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip sx={{ p: 0 }} key={value} label={value} />
                  ))}
                </Box>
              );
            }}
          >
            {$roles.roles.map((el) => (
              <MenuItem
                disabled={el.name === "user"}
                key={el.name}
                value={el.name}
              >
                {el.description}
              </MenuItem>
            ))}
          </SelectSt>
          <FormHelperText>{errors.role}</FormHelperText>
        </FormControl>

        <Alert severity="info" sx={{ mb: 3 }}>
          Пароль будет отправлен на указанный <strong>E-mail</strong>
        </Alert>

        <LoadingButton
          sx={{ float: "right" }}
          type="submit"
          loading={btnLoading}
          variant="contained"
        >
          Сохранить
        </LoadingButton>
      </form>
    </Modal>
  );
});

export default CreateUserModal;
