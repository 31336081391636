import { types } from "mobx-state-tree";
import { api } from "../../api";
import { flow } from "mobx";
import { $spinner } from "../spinner";
import { $snackbar } from "../snackbar";
import { union } from "lodash";
import { $products } from "./productsInTags";

export const $tags = types
  .model({
    tagsList: types.frozen([]),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchTagsList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setTags([]);
      }
      try {
        $spinner.show();
        const res = yield api()
          .get(`admin/tags`, {
            params: {
              limit:
                type !== "paginate" && type === "fetchAll" ? 1000 : self.limit,
              offset: self.offset,
            },
          })
          .then((res) => res.data);
        self.setHasMore(res.length >= self.limit);
        self.setTags(type === "paginate" ? union(self.tagsList, res) : res);
        $spinner.hide();
      } catch (err) {
        $spinner.hide();
        console.error("fetch tags list: ", err);
        throw err;
      }
    }),

    addNewTag: flow(function* (name) {
      try {
        const res = yield api()
          .post(`admin/tags`, {
            value: name,
            recommended_tag: 1,
            is_promo: 0,
            priority: 2,
          })
          .then((res) => res.data);
        $products.setCurrentTagId(res.id);
      } catch (err) {
        $snackbar.show("Ошибка сохранения тега");
        throw err;
      }
    }),

    editTag: flow(function* (id, name) {
      try {
        yield api()
          .put(`admin/tags/${id}`, {
            value: name,
            recommended_tag: 1,
            is_promo: 0,
            priority: 2,
          })
          .then((res) => res.data);
      } catch (err) {
        console.error("fetch tags list: ", err);
        throw err;
      }
    }),

    deleteTag: flow(function* (id) {
      try {
        yield api()
          .delete(`admin/tags/${id}`)
          .then((res) => res.data);
        $products.setCurrentTagId("");
      } catch (err) {
        console.error("fetch tags list: ", err);
        throw err;
      }
    }),
    setTags(data) {
      self.tagsList = data;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchTagsList("paginate");
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLimit(data) {
      self.limit = data;
    },
  }))
  .create();
