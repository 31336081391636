import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Button,
  ImageListItem,
  ImageList,
  Typography,
  TextField,
  ImageListItemBar,
} from "@mui/material";
import { Rating } from "@mui/lab";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { $modals } from "store/modals";
import { DELETE_REVIEW_MODAL } from "ui-components/modal/modals";
import { $reviews } from "store/reviews/reviews";

const userPhotos = [
  "https://feedback01.wb.ru/vol200/part20058/20058719/photos/fs.jpg",
  "https://feedback02.wb.ru/vol819/part81913/81913474/photos/fs.jpg",
  "https://feedback02.wb.ru/vol819/part81913/81913475/photos/fs.jpg",
  "https://feedback02.wb.ru/vol819/part81913/81913476/photos/fs.jpg",
];

export const ReviewCard = ({ review }) => {
  const [editMode, setEditMode] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxSrc, setLightBoxSrc] = useState(false);

  const editClicked = () => {
    setEditMode(true);
  };

  const cancelEditingClicked = () => {
    setEditMode(false);
  };

  const deleteReviewClicked = () => {
    $modals.show(DELETE_REVIEW_MODAL, review);
  };

  const acceptClicked = () => {
    $reviews.accept();
  };

  const previewClicked = (src) => {
    setLightBoxOpen(true);
    setLightBoxSrc(src);
  };

  return (
    <Card className="review-card">
      <CardContent>
        {lightBoxOpen && (
          <Lightbox
            mainSrc={lightBoxSrc}
            // nextSrc={images[(photoIndex + 1) % images.length]}
            // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setLightBoxOpen(false)}
            // onMovePrevRequest={() =>
            //   this.setState({
            //     photoIndex: (photoIndex + images.length - 1) % images.length,
            //   })
            // }
            // onMoveNextRequest={() =>
            //   this.setState({
            //     photoIndex: (photoIndex + 1) % images.length,
            //   })
            // }
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <div>
              <Avatar
                src={"https://img.tovarbezpereplat.ru/products/1018469_0.jpg"}
                sx={{ width: "100%", height: "100%", marginBottom: "8px" }}
                variant="square"
              >
                N
              </Avatar>
              <Typography component="legend">Оценка товара</Typography>
              <Rating
                readOnly
                name="simple-1"
                value={5}
                // size={"small"}
                onChange={(event, newValue) => {
                  // setValue(newValue);
                }}
              />
            </div>
            <div>
              <Typography component="legend">Оценка фото</Typography>
              <Rating
                readOnly
                name="simple-2"
                value={3}
                // size={"small"}
                onChange={(event, newValue) => {
                  // setValue(newValue);
                }}
              />
            </div>
            <div>
              <Typography component="legend">Оценка еще чего то</Typography>
              <Rating
                readOnly
                name="simple-3"
                value={3}
                // size={"small"}
                onChange={(event, newValue) => {
                  // setValue(newValue);
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={7}>
            <div className="product-label">
              <a
                href="https://tovarbezpereplat.ru/products/1001155"
                target="_blank"
                rel="noopener"
              >
                ИНКАНТО колготки Элле 40 ден натурель/телесные 2
              </a>
            </div>
            <div className="phone-label">
              <a
                href="https://dev-admin.tovarbezpereplat.ru/chat?customer_id=101982"
                target="_blank"
              >
                +79274905614
              </a>
            </div>

            {editMode ? (
              <TextField
                multiline
                fullWidth
                value={
                  "Мне очень понравились, удобные, комфортные, не слезают и не впиваются) чем-то напоминают колготки из маленьких бумажных коробочек. Брала размер больше, поэтому особо утяжки не было. Цвет подошел на незагорелые ноги, не было эффекта блестящих, толстых капронок😁 за такую цену-подарок. Если бы не прицепилась липучкой, остались бы целы( не было никакого рецепта внутри, возможно, разные партии)"
                }
                rows={6}
                maxRows={6}
              />
            ) : (
              <div>
                Мне очень понравились, удобные, комфортные, не слезают и не
                впиваются) чем-то напоминают колготки из маленьких бумажных
                коробочек. Брала размер больше, поэтому особо утяжки не было.
                Цвет подошел на незагорелые ноги, не было эффекта блестящих,
                толстых капронок😁 за такую цену-подарок. Если бы не прицепилась
                липучкой, остались бы целы( не было никакого рецепта внутри,
                возможно, разные партии)
              </div>
            )}

            <ImageList sx={{ mt: 2 }} cols={5}>
              {userPhotos.map((item) => (
                <ImageListItem style={{ marginRight: "1px" }} key={item}>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => previewClicked(item)}
                    src={item}
                    srcSet={item}
                    alt={"alt"}
                    loading="lazy"
                  />
                  {editMode && (
                    <ImageListItemBar
                      subtitle={<div className="delete-label">удалить</div>}
                      position="below"
                    />
                  )}
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>

          <Grid item xs={12} md={2}>
            {editMode ? (
              <>
                <Button
                  className="action-button"
                  fullWidth
                  variant={"contained"}
                >
                  Сохранить и принять
                </Button>
                <Button
                  color={"error"}
                  onClick={cancelEditingClicked}
                  className="action-button"
                  fullWidth
                  variant={"outlined"}
                >
                  Отменить
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="action-button"
                  fullWidth
                  variant={"contained"}
                  onClick={acceptClicked}
                >
                  Принять
                </Button>
                <Button
                  onClick={editClicked}
                  className="action-button"
                  fullWidth
                  variant={"outlined"}
                >
                  Редактировать
                </Button>
                <Button
                  className="action-button"
                  fullWidth
                  color={"error"}
                  variant={"outlined"}
                  onClick={deleteReviewClicked}
                >
                  Удалить
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
