import { types } from "mobx-state-tree";
import { api } from "api";
import { flow } from "mobx";
import { USER_STATUS_BLOCKED_PURCHASE } from "lib/userStatuses";
import { $spinner } from "../spinner";
import { union } from "lodash";
import { $snackbar } from "../snackbar";

export const $customers = types
  .model("Customers", {
    list: types.frozen([]),
    allUsersCount: types.optional(types.integer, 0),
    activeUsersCount: types.optional(types.integer, 0),
    showBlockedOnly: types.optional(types.boolean, false),

    searchValue: types.optional(types.string, ""),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setUsers([]);
      }
      $spinner.show();
      try {
        const res = yield api()
          .get(`reports/users`, {
            params: {
              limit: self.limit,
              offset: self.offset,
              ...(self.showBlockedOnly && {
                status: USER_STATUS_BLOCKED_PURCHASE,
              }),
              ...(self.searchValue && { query: self.searchValue }),
            },
          })
          .then((res) => res.data);

        self.setHasMore(res.length >= self.limit);
        self.setUsers(type === "paginate" ? union(self.list, res) : res);
      } catch (err) {
        $snackbar.show("Ошибка получения списка");
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    fetchUsersStats: flow(function* () {
      try {
        const res = yield api()
          .get(`reports/users-statistic`)
          .then((res) => res.data);
        self.setUsersStats(res);
      } catch (err) {
        console.error("get users error: ", err);
        throw err;
      }
    }),
    setUsers(data) {
      self.list = data;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setUsersStats(data) {
      self.allUsersCount = data.all;
      self.activeUsersCount = data.active;
    },
    setShowBlockedOnly(value) {
      self.showBlockedOnly = value;
      self.fetchList();
    },
    setSearchValue(value) {
      self.searchValue = value;
      self.fetchList();
    },
    paginate() {
      self.offset += self.limit;
      self.fetchList("paginate");
    },
    resetPaginate() {
      self.offset = 0;
    },
  }))
  .create();
