/* eslint-disable no-undef*/
const API_URL = process.env.REACT_APP_API_URL;
const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API_URL;
const CONSULTANT_API_URL = process.env.REACT_APP_CONSULTANT_API_URL;
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;

export default {
  LARAVEL_API_URL,
  API_URL,
  CONSULTANT_API_URL,
  PUSHER_KEY,
};
