import axios from "axios";
import config from "../config";
import iziToast from "izitoast";

export const noPrefixEndpoint = `${config.API_URL}`;
export const v1Endpoint = `${config.API_URL}api/v1/`;
export const v2Endpoint = `${config.API_URL}api/v2/`;
export const v3Endpoint = `${config.LARAVEL_API_URL}/api/v3/admin`;
export const v3EndpointCommon = `${config.LARAVEL_API_URL}/api/v3`;
export const consultantEndpoint = `${config.CONSULTANT_API_URL}/api`;

export const api = (endpoint = v1Endpoint) => {
  const instance = axios.create({
    baseURL: endpoint,
    timeout: 20000,
    headers: {
      "Device-Type": 0,
    },
  });

  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error.response.data.message;

      if (error.response) {
        switch (error.response.status) {
          case 401:
            localStorage.removeItem("jwtToken");
            iziToast.error({
              message: "Ошибка авторизации",
            });
            break;
          case 500:
            iziToast.error({
              message: message || "Ошибка сервера",
            });
            break;
          case 400:
            iziToast.error({
              title: "Bad request",
              message: "Обратитесь к администратору",
            });
            break;
          case 403:
            iziToast.error({
              message: error.response.data.message,
            });
            break;
          case 404:
            iziToast.error({
              message: message || "Данные не найдены",
            });
            break;
          case 405:
            iziToast.error({
              message: "Method not allowed",
            });
            break;
          case 429:
            iziToast.error({
              message: "429 - Слишком много запросов",
            });
            break;
          default:
            break;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
