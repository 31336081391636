import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { InfiniteScroll } from "ui-components/scrollingTable";
import { $permissions } from "store/permissions/permissions";
import { format, parseISO } from "date-fns";

const columns = [
  {
    id: "id",
    label: "№",
  },
  {
    id: "name",
    label: "Роль",
  },
  {
    id: "view",
    label: "Просмотр",
  },
  {
    id: "edit",
    label: "Редактирование",
  },
  {
    id: "updatedAt",
    label: "Изменено",
  },
];

export const PermissionsTable = observer(() => {
  const RenderCheckbox = ({ role, action }) => {
    const isDeveloper = role.role_name === "developer";
    return (
      <Checkbox
        disabled={isDeveloper}
        onChange={(e) => $permissions.togglePermission(role.id, action)}
        checked={isDeveloper ? true : !!role[action]}
      />
    );
  };

  return (
    <InfiniteScroll hasMore={false}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$permissions.permissions.map((role, key) => (
            <TableRow hover tabIndex={-1} key={key}>
              <TableCell>{key + 1}</TableCell>
              <TableCell>{role.role_name}</TableCell>
              <TableCell>
                <RenderCheckbox role={role} action="view" />
              </TableCell>
              <TableCell>
                <RenderCheckbox role={role} action="edit" />
              </TableCell>
              <TableCell>
                {format(parseISO(role.updated_at), "dd.MM.yyyy HH:mm")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});
