import {
  Avatar,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { InfiniteScroll } from "ui-components/scrollingTable";
import { $remarks } from "store/remarks/remarks";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const columns = [
  {
    id: "status",
    label: "Статус",
  },
  {
    id: "remark",
    label: "Ошибка",
  },
  {
    id: "type",
    label: "Тип",
  },
  {
    id: "product",
    label: "Товар",
  },
  {
    id: "user",
    label: "Пользователь",
  },
];

const typeNames = [
  "",
  "Неверная фотография",
  "Ошибка в описании",
  "Неадекватная цена",
  "Неверная группа",
  "Другое",
];

const renderStatus = (remark) => {
  if (remark.status !== 1) {
    return (
      <IconButton disabled>
        <CheckCircleOutlineOutlinedIcon sx={{ color: "#4db915" }} />
      </IconButton>
    );
  } else {
    return (
      <Tooltip title="Исправить">
        <IconButton onClick={() => $remarks.correct(remark.id)}>
          <AutoFixHighOutlinedIcon sx={{ color: "#1e90bb" }} />
        </IconButton>
      </Tooltip>
    );
  }
};

const RemarksTable = observer(() => {
  return (
    <InfiniteScroll
      hasMore={$remarks.hasMore}
      onScroll={() => $remarks.paginate()}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$remarks.list.map((remark, key) => {
            return (
              <TableRow hover tabIndex={-1} key={key}>
                <TableCell>{renderStatus(remark)}</TableCell>
                <TableCell style={{ maxWidth: "200px" }}>
                  {remark.text ? remark.text : "—"}
                </TableCell>
                <TableCell>{typeNames[remark.type]}</TableCell>
                <TableCell style={{ maxWidth: "200px" }}>
                  <a
                    target="_blank"
                    href={`https://tovarbezpereplat.ru/products/${remark.product_id}`}
                  >
                    {remark.product.name}
                  </a>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex" }}>
                    <Avatar sx={{ mr: 2 }} src={remark.userProfile.avatar} />
                    <div>
                      <div>{remark.userProfile.name}</div>
                      <div>{remark.userProfile.phone}</div>
                    </div>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default RemarksTable;
