import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { $suggestions } from "store/suggestions/suggestions";
import SuggestionsTable from "./table";
import { Button } from "@mui/material";
import { $modals } from "store/modals";
import {
  NEW_CATEGORY_MODAL,
  NEW_SUGGESTION_MODAL,
} from "ui-components/modal/modals";
import { isMobile } from "react-device-detect";
import { $sidebar } from "store/sidebar";

const Suggestions = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Автосообщения");
    $suggestions.fetchCategories();
  }, []);

  const createCategoryClicked = () => {
    $modals.show(NEW_CATEGORY_MODAL);
  };

  const createSuggestionClicked = () => {
    $modals.show(NEW_SUGGESTION_MODAL);
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Button
          sx={{ mr: 2 }}
          variant="contained"
          onClick={createCategoryClicked}
        >
          Создать категорию
        </Button>
        {isMobile && <Box sx={{ mb: 1 }} />}
        <Button variant="contained" onClick={createSuggestionClicked}>
          Создать сообщение
        </Button>
      </Box>
      <SuggestionsTable />
    </>
  );
});

export default Suggestions;
