import { format, fromUnixTime, parseISO } from "date-fns";

// pretty print from "1231231" to "1 231 231"
export function numberWithSpaces(x) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function parseUnixTime(unix) {
  return unix ? format(fromUnixTime(unix), "dd.MM.yyyy HH:mm:ss") : null;
}

export function parseUnixTimeDate(unix) {
  return unix ? format(fromUnixTime(unix), "dd.MM.yyyy") : null;
}

export function parseIsoDate(date) {
  if (!date) return null;
  return format(parseISO(date), "dd.MM.yyyy");
}

export function parseIsoDateTime(date) {
  if (!date) return null;
  return format(parseISO(date), "dd.MM.yyyy HH:mm:ss");
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
