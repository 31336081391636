import { IconButton, InputBase, Paper } from "@mui/material";
import { isMobile } from "react-device-detect";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React from "react";

export const InputSearch = ({ placeholder, onChange }) => {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: isMobile ? "100%" : 400,
        borderRadius: "12px",
        border: "1px solid rgba(144, 202, 249, 0.46)",
        boxShadow: "none",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="menu">
        <SearchOutlinedIcon />
      </IconButton>
      <InputBase
        onKeyPress={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onChange={onChange}
      />
    </Paper>
  );
};
