import { types } from "mobx-state-tree";

export const $snackbar = types
  .model({
    open: types.optional(types.boolean, false),
    label: types.optional(types.string, ""),
    type: types.optional(types.string, "error"),
    items: types.frozen(),
  })
  .actions((self) => ({
    show(label, type) {
      self.open = true;
      self.label = label;
      self.type = type;
    },
    close() {
      self.open = false;
      self.label = "";
    },
  }))
  .create();
