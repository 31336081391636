import React from "react";
import {
  Box,
  TextField
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {useState} from "react";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {$products} from "store/tags/productsInTags";
import {LoadingButton} from "@mui/lab";

const NewTagModal = observer(() => {

  const [barcode, setBarcode] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setError(null);
    $products.addProductToTag(barcode)
      .then(() => {
        $products.fetchProductsInTagList($products.currentTagId);
        $modals.close();
      })
      .catch(err => {
        if (err.response) {
          setError(err.response.data.message);
        }
      })
      .finally(() => setBtnLoading(false));
  }

  return (
    <Modal title="Добавить товар в текущий тег" subheader="Введите штрих-код товара">
      <form onSubmit={e => onSubmit(e)}>
        <TextField
          inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
          error={!!error}
          required
          onChange={e => setBarcode(e.target.value)}
          fullWidth sx={{mb: 3}}
          label="Введите штрихкод товара"
          variant="outlined"
          helperText={error}
        />
        <Box sx={{textAlign: 'right'}}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={btnLoading}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  )
});

export default NewTagModal;