import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import Header from "./header";
import Sidebar from "./sidebar";
import { $sidebar } from "../store/sidebar";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import AppRoutes from "../routes";
import { $permissions } from "../store/permissions/permissions";
import { useLocation } from "react-router-dom";

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, variant, open }) => {
  return {
    ...theme.typography.mainContent,
    background: "#e3f2fd",
    height: "calc(100vh - 88px)",
    overflow: isMobile ? "auto" : "hidden",
    marginTop: "88px",
    marginLeft: isMobile ? 0 : (open ? "280px" : "20px"),
    marginRight: isMobile ? 0 : "20px",
    padding: variant === "light" ? 0 : "20px",
    border: variant === "light" ? "1px solid #E7E8EA" : "",
    borderRadius: "12px 12px 0 0",
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: '.2s',
    }),
  };
});

const MainLayout = observer(({ header }) => {
  const theme = useTheme();
  const location = useLocation();

  if (!$permissions._initPermissions) return null;

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "#f8f8f8",
      }}
    >
      <Header header={header} />
      <Sidebar />
      <Main
        theme={theme}
        open={$sidebar.visible}
        variant={location.pathname === "/chat" ? "light" : "default"}
      >
        <AppRoutes />
      </Main>
    </Box>
  );
});

export default MainLayout;
