import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React from "react";
import { observer } from "mobx-react-lite";
import { InfiniteScroll } from "ui-components/scrollingTable";
import { $modals } from "store/modals";
import { $products } from "store/tags/productsInTags";
import { DELETE_PRODUCT_FROM_TAG_MODAL } from "ui-components/modal/modals";
import { parseUnixTime } from "lib/features";

const columns = [
  {
    id: "id",
    label: "id",
  },
  {
    id: "barcode",
    label: "Штрих-код",
  },
  {
    id: "date",
    label: "Создан",
  },
  {
    id: "name",
    label: "Наименование",
  },
  {
    id: "picture",
    label: "Изображение",
  },
  {
    id: "actions",
    label: "Действия",
  },
];

const ProductsAndTagsTable = observer(({ list }) => {
  const deleteProductFromTag = (product) => {
    $modals.show(DELETE_PRODUCT_FROM_TAG_MODAL, product);
  };

  const printCreatedAt = (product) => {
    const productTag = product.productTags.find(
      (el) => el.tag_id === $products.currentTagId
    );

    if (!productTag) return null;

    return productTag.created_at ? parseUnixTime(productTag.created_at) : "-";
  };

  return (
    <InfiniteScroll
      hasMore={$products.hasMore}
      onScroll={() => $products.paginate()}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((product, key) => (
              <TableRow hover tabIndex={-1} key={key}>
                <TableCell>{product.id}</TableCell>
                <TableCell>
                  {product.barcodes.length > 0 &&
                    product.barcodes[product.barcodes.length - 1].barcode}
                </TableCell>
                {<TableCell>{printCreatedAt(product)}</TableCell>}
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  {product.images.length > 0 && (
                    <Box
                      component="img"
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                      }}
                      alt="img"
                      src={product.images[0].url}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Удалить">
                    <IconButton onClick={() => deleteProductFromTag(product)}>
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default ProductsAndTagsTable;
