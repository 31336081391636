import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {$promo} from "store/promo";
import {$networks} from "store/promo";
import {isMobile} from "react-device-detect";
import PromoTable from "./table";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Add from '@mui/icons-material/Add';
import {$modals} from "store/modals";
import {NEW_PROMO_MODAL} from "ui-components/modal/modals";

const Promo = observer(() => {
  useEffect(() => {
    $promo.fetchPromoList()
    $networks.fetchNetworksList()
  }, [])

  const createNewPromo = () => {
    $modals.show(NEW_PROMO_MODAL);
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        {$networks.networksList.map((network) => (
          <Chip
            key={network.name}
            label={network.name}
            avatar={<Avatar alt={network.name} src={network.image_url}/>}
            onDelete={() => console.log('deleted')}
            onClick={() => console.log('clicked')}
          />
        ))}
        <Chip
          label='Добавить'
          onClick={() => console.log('deleted')}
        />
      </Stack>
      <Box sx={{m: '5px 0 10px 5px', fontSize: isMobile ? 12 : 18, fontWeight: 'bold'}}>
        <Button variant="contained" startIcon={<Add/>} onClick={createNewPromo}>Создать каталог</Button>
      </Box>
      {!isMobile && <PromoTable/>}
    </>
  )
})

export default Promo;