import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { $complaints } from "store/complaints";
import { isMobile } from "react-device-detect";
import ComplaintsTable from "./table";
import Chart from "react-apexcharts";
import { $sidebar } from "store/sidebar";
import DatePicker from "@mui/lab/DatePicker";
import ru from "date-fns/locale/ru";

const Complaints = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Жалобы покупателей");
    $complaints.fetchComplaintsList();
  }, []);

  const chart = {
    options: {
      colors: ["#FF1654"],
      stroke: {
        width: [3],
        curve: "smooth",
      },
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        type: "datetime",
        categories: $complaints.chartCategories,
      },
    },
    series: [
      {
        name: "Жалобы",
        data: $complaints.chartSeries,
      },
    ],
  };

  return (
    <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
      <>
        <Typography variant="h4" component="div" sx={{ mb: 2 }}>
          Жалобы покупателей
        </Typography>
        <div
          style={{
            background: "#fff",
            borderRadius: "12px",
            marginBottom: "14px",
            border: "1px solid rgba(144, 202, 249, 0.45)",
          }}
        >
          <Chart
            options={chart.options}
            series={chart.series}
            type="line"
            height="100%"
          />
        </div>
        <Box sx={{ maxWidth: "300px", marginBottom: "12px" }}>
          <DatePicker
            clearText="Очистить"
            clearable
            label="Дата"
            value={$complaints.date}
            onChange={(newValue) => $complaints.setDate(newValue)}
            renderInput={(params) => <TextField name={"datefns"} {...params} />}
          />
        </Box>
        {!isMobile && <ComplaintsTable />}
      </>
    </LocalizationProvider>
  );
});

export default Complaints;
