import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { $customers } from "store/customers/customers";
import React from "react";
import { observer } from "mobx-react-lite";
import {
  getBlockLabel,
  getDeleteLabel,
  getIsBlocked,
  getIsDeleted,
  USER_STATUS_DELETED,
  USER_STATUS_WAIT,
  userStatusNames,
} from "lib/userStatuses";
import BlockIcon from "@mui/icons-material/Block";
import { $modals } from "store/modals";
import {
  BLOCK_CUSTOMER_MODAL,
  DELETE_CUSTOMER_MODAL,
} from "ui-components/modal/modals";
import { parseUnixTime } from "lib/features";
import { InfiniteScroll } from "ui-components/scrollingTable";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";

const columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "avatar",
    label: "Аватар",
  },
  {
    id: "name",
    label: "Имя",
  },
  {
    id: "phone",
    label: "Телефон",
  },
  {
    id: "last_visit",
    label: "Последний вход",
  },
  {
    id: "created_at",
    label: "Дата регистрации",
  },
  {
    id: "activity",
    label: "Активность",
  },
  {
    id: "status",
    label: "Статус",
  },
  {
    id: "actions",
    label: "Действия",
    minWidth: "112px",
    align: "right",
  },
];

const DAY_BEFORE = new Date().getTime() / 1000 - 90000;

const CustomersTable = observer(() => {
  const deleteCustomerClicked = (user) => {
    $modals.show(DELETE_CUSTOMER_MODAL, user);
  };

  const blockPurchasesClicked = (user) => {
    $modals.show(BLOCK_CUSTOMER_MODAL, user);
  };

  return (
    <InfiniteScroll
      hasMore={$customers.hasMore}
      onScroll={() => $customers.paginate()}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$customers.list.map((user, key) => {
            const userStatus = userStatusNames[user.status];

            return (
              <TableRow hover tabIndex={-1} key={key}>
                <TableCell>{user.id}</TableCell>
                <TableCell>
                  <Avatar alt={user.name.toString()} src={user.avatar} />
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{parseUnixTime(user.last_visit)}</TableCell>
                <TableCell>{parseUnixTime(user.created_at)}</TableCell>
                <TableCell>
                  {user.last_visit >= DAY_BEFORE ? "Да" : "Нет"}
                </TableCell>
                <TableCell
                  sx={userStatus === "Активен" ? { color: "green" } : {}}
                >
                  {userStatus}
                </TableCell>
                <TableCell align="right">
                  {![USER_STATUS_WAIT, USER_STATUS_DELETED].includes(
                    user.status
                  ) && (
                    <Tooltip title={getBlockLabel(user)}>
                      <IconButton onClick={() => blockPurchasesClicked(user)}>
                        {getIsBlocked(user) ? (
                          <SettingsBackupRestoreOutlinedIcon color="info" />
                        ) : (
                          <BlockIcon color="error" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={getDeleteLabel(user)}>
                    <IconButton onClick={() => deleteCustomerClicked(user)}>
                      {getIsDeleted(user) ? (
                        <RestoreFromTrashOutlinedIcon color="info" />
                      ) : (
                        <DeleteOutlineOutlinedIcon color="error" />
                      )}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default CustomersTable;
