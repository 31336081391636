import React from "react";
import { Modal } from "ui-components/modal";
import {
  TextField,
  Box,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { $modals } from "store/modals";
import { getBlockLabel } from "lib/userStatuses";
import {
  $customerActions,
  autoBlockTypesNames,
  operatorBlockTypesNames,
  typeIcons,
} from "store/customers/actions";
import { parseUnixTime } from "lib/features";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { $customers } from "../../store/customers/customers";
import { $common } from "../../store/common";
import { $chatOrders } from "../../store/chat/orders";

const ListSt = styled(List)`
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 10px;

  .title {
    font-weight: 600;
    padding-bottom: 4px;
  }
`;

const TextMuted = styled.div`
  color: #9a9a9a;
  padding-top: 10px;
`;

const BlockCustomerModal = observer(() => {
  const user = $modals.params;

  const [btnLoading, setBtnLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [comment, setComment] = useState("");

  const handleBlock = () => {
    setBtnLoading(true);
    $customerActions
      .handleBlock(user, comment)
      .then(() => {
        if ($common.currentRoute === "/chat") {
          $chatOrders.getOrders();
        } else {
          $customers.fetchList();
        }
      })
      .finally(() => {
        setBtnLoading(false);
        $modals.close();
      });
  };

  const getBlockedType = (item) => {
    const { is_auto, type } = item;
    if (is_auto) {
      return autoBlockTypesNames[type];
    } else {
      return operatorBlockTypesNames[type];
    }
  };

  useEffect(() => {
    setHistoryLoading(true);
    $customerActions
      .fetchBlockHistory(user.id)
      .finally(() => setHistoryLoading(false));
  }, []);

  return (
    <Modal
      title={getBlockLabel(user)}
      subheader={`Вы действительно хотите ${getBlockLabel(
        user
      ).toLowerCase()} покупателя?`}
    >
      <TextField
        onChange={(e) => setComment(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
        label="Укажите причину"
        variant="filled"
        focused
      />
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          loading={btnLoading}
          variant="contained"
          onClick={handleBlock}
        >
          {getBlockLabel(user)}
        </LoadingButton>
      </Box>

      <div className="title">История блокировок:</div>

      {historyLoading && (
        <Box className="flex-center" sx={{ pt: 1 }}>
          <CircularProgress />
        </Box>
      )}

      {$customerActions.blockHistory.count === 0 && !historyLoading && (
        <TextMuted>Блокировки отсутствуют</TextMuted>
      )}

      {$customerActions.blockHistory.count > 0 && (
        <ListSt>
          {$customerActions.blockHistory.comment.map((item, key) => (
            <ListItem sx={{ pl: 0, pr: 0 }} key={key} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar src={item.moderatorProfile.avatar || ""} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <span style={{ fontWeight: 500 }}>
                      {getBlockedType(item)}
                    </span>
                    <br />
                    {item.comment}
                  </>
                }
                secondary={
                  !item.blocked_at
                    ? parseUnixTime(item.created_at)
                    : `С ${parseUnixTime(user.created_at)} по ${parseUnixTime(
                        user.blocked_at
                      )}`
                }
              />
              <ListItemIcon>{typeIcons[item.type]}</ListItemIcon>
            </ListItem>
          ))}
        </ListSt>
      )}
    </Modal>
  );
});

export default BlockCustomerModal;
