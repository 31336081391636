import { types } from "mobx-state-tree";

export const $store = types
  .model({
    stores: types.frozen([]),
  })
  .views((self) => ({
    getFilteredStores(selectedCityIds) {
      if (selectedCityIds.length === 0) return self.stores;
      return self.stores.filter((el) => {
        if (selectedCityIds.includes(el.city_id)) {
          return el;
        }
      });
    },
  }))
  .actions((self) => ({
    setStores(value) {
      self.stores = value;
    },
  }))
  .create();
