import { types } from "mobx-state-tree";
import { api, v3EndpointCommon } from "../api";
import { flow } from "mobx";
import { $spinner } from "store/spinner";
import { initRequests } from "../init";

export const $user = types
  .model("User", {
    profile: types.frozen([]),
    isAuthorized: types.optional(types.boolean, false),
    isDeveloper: types.optional(types.boolean, false),
    roles: types.frozen([]),
  })
  .views((self) => ({
    get isCourier() {
      return !!self.roles.find((role) => role.name === "courier");
    },
  }))
  .actions((self) => ({
    login: flow(function* (phone, password) {
      try {
        $spinner.show();
        const JWT = yield api(v3EndpointCommon)
          .post("login", {
            phone,
            password,
          })
          .then((res) => res.data.access_token);
        localStorage.setItem("jwtToken", JWT);
        initRequests();
      } catch (err) {
        console.error("auth user error: ", err);
        $user.setIsAuthorized(false);
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    setProfile(profile) {
      self.profile = profile;
    },
    setIsAuthorized(value) {
      self.isAuthorized = value;
    },
    logout() {
      return new Promise((resolve) => {
        localStorage.removeItem("jwtToken");
        $user.setIsAuthorized(false);
        $user.setProfile([]);
        resolve();
      });
    },
    setIsDeveloper(value) {
      self.isDeveloper = value;
    },
    setRoles(value) {
      self.roles = value;
    },
  }))
  .create();
