import React from "react";
import { AppBar, Avatar, Box, Chip, IconButton, Toolbar } from "@mui/material";
import { observer } from "mobx-react-lite";
import { $sidebar } from "../store/sidebar";
import { IconMenu2, IconSettings } from "@tabler/icons";
import { $user } from "../store/user";
import { useTheme } from "@mui/material/styles";
import { isMobile } from "react-device-detect";

const Header = observer(() => {
  const handleToggleProfile = () => {
    alert("пока не работает :(");
  };

  const theme = useTheme();
  return (
    <AppBar
      sx={{ height: "64px", zIndex: 1000 }}
      color="inherit"
      enableColorOnDark
      elevation={0}
      position="fixed"
    >
      <Toolbar>
        <IconButton
          onClick={() => $sidebar.toggle()}
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <IconMenu2 />
        </IconButton>
        <Box
          sx={{
            fontSize: isMobile ? "14px" : "18px",
            fontWeight: 500,
            fontFamily: "Open Sans, sans-serif!important",
            textTransform: "uppercase",
          }}
        >
          {$sidebar.pageTitle}
        </Box>

        <div style={{ marginLeft: "auto", display: "flex" }}>
          {!isMobile && (
            <Box sx={{ mt: 1, paddingTop: "3px" }}>
              {$user.roles.map((el) => (
                <Chip
                  sx={{ mr: 1 }}
                  label={el.description}
                  size="small"
                  key={el.description}
                />
              ))}{" "}
            </Box>
          )}
          <Chip
            sx={{
              marginLeft: "auto",
              cursor: "pointer",
              height: "48px",
              alignItems: "center",
              borderRadius: "27px",
              transition: "all .2s ease-in-out",
              borderColor: "#e3f2fd",
              backgroundColor: "#e3f2fd",
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: "#2196f3",
                background: `#2196f3!important`,
                color: theme.palette.primary.light,
                "& svg": {
                  stroke: "#fff",
                },
              },
              "& .MuiChip-label": {
                lineHeight: 0,
              },
            }}
            icon={
              <Avatar
                src={$user.profile.avatar}
                sx={{
                  width: "34px",
                  height: "34px",
                  margin: "8px 0 8px 8px !important",
                  cursor: "pointer",
                }}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label={
              <IconSettings
                stroke={1.5}
                size="1.5rem"
                color={theme.palette.primary.main}
              />
            }
            variant="outlined"
            aria-haspopup="true"
            onClick={handleToggleProfile}
            color="primary"
          />
        </div>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
