import { types } from "mobx-state-tree";

export const $geo = types
  .model({
    regions: types.frozen([]),
    cities: types.frozen([]),
  })
  .views((self) => ({
    getFilteredCities(selectedRegionIds) {
      if (selectedRegionIds.length === 0) return self.cities;
      return self.cities.filter((el) => {
        if (selectedRegionIds.includes(el.geo_region_id)) {
          return el;
        }
      });
    },
  }))
  .actions((self) => ({
    setRegions(value) {
      self.regions = value;
    },
    setCities(value) {
      self.cities = value;
    },
  }))
  .create();
