import {
  ORDER_STATUS_COLLECT,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_ISSUED,
  ORDER_STATUS_MODERATION,
  ORDER_STATUS_NEW,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_WAITING,
} from "../../../../lib/orderStatuses";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { $modals } from "store/modals";
import {
  DELETE_PRODUCT_FROM_ORDER_MODAL,
  RETURN_PRODUCT_FROM_ORDER_MODAL,
} from "ui-components/modal/modals";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import React, { useState } from "react";
import List from "@mui/material/List";

export const ReturnProductButton = ({
  product,
  status,
  storeId,
  orderBarcode,
}) => {
  // {order.handed_at || order.status === ORDER_STATUS_DELIVERED ? (
  //   <Tooltip placement="left" title="Вернуть товар">
  //     <IconButton
  //       onClick={() =>
  //         $modals.show(RETURN_PRODUCT_FROM_ORDER_MODAL, {
  //           product: product,
  //           shop_id: order.store_id,
  //         })
  //       }
  //     >
  //       <ProductionQuantityLimitsOutlinedIcon
  //         style={{ color: "#ff731a" }}
  //       />
  //     </IconButton>
  //   </Tooltip>
  // ) : (
  //   order.status !== ORDER_STATUS_DELETED && (
  //     <Tooltip placement="left" title="Удалить товар из заказа">
  //       <IconButton
  //         onClick={() => removeProductClicked(product)}
  //         sx={{ ml: 1 }}
  //         size="small"
  //       >
  //         <ClearOutlinedIcon style={{ color: "red" }} />
  //       </IconButton>
  //     </Tooltip>
  //   )
  // )}

  const removeProductClicked = () => {
    $modals.show(DELETE_PRODUCT_FROM_ORDER_MODAL, product);
  };

  const returnProductClicked = () => {
    $modals.show(RETURN_PRODUCT_FROM_ORDER_MODAL, {
      product: product,
      shopId: storeId,
      orderBarcode: orderBarcode,
    });
  };

  const [showTypeAlert, setShowTypeAlert] = useState(false);

  const selectReturnTypeClicked = () => {
    setShowTypeAlert(true);
  };

  const removeStatuses = [
    ORDER_STATUS_NEW,
    ORDER_STATUS_WAITING,
    ORDER_STATUS_MODERATION,
  ];

  const returnStatuses = [
    ORDER_STATUS_SHIPPED,
    ORDER_STATUS_DELIVERED,
    ORDER_STATUS_ISSUED,
  ];

  if (removeStatuses.includes(status)) {
    return (
      <Tooltip placement="left" title="Удалить товар из заказа">
        <IconButton onClick={removeProductClicked} sx={{ ml: 1 }} size="small">
          <ClearOutlinedIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
    );
  } else if (returnStatuses.includes(status)) {
    return (
      <Tooltip placement="left" title="Вернуть товар">
        <IconButton sx={{ ml: 1 }} onClick={returnProductClicked}>
          <ProductionQuantityLimitsOutlinedIcon style={{ color: "#ff731a" }} />
        </IconButton>
      </Tooltip>
    );
  } else if (ORDER_STATUS_COLLECT === status) {
    return (
      <>
        <Dialog open={showTypeAlert} onClose={() => setShowTypeAlert(false)}>
          <List sx={{ pt: 0 }}>
            <DialogTitle sx={{ fontSize: "18px" }}>
              Выберите тип возврата
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Статус заказа <b>Принят на сборку</b>, выберите тип возврата:
              </DialogContentText>
            </DialogContent>
            <ListItem>
              <ListItemButton onClick={removeProductClicked}>
                <ListItemText primary="1) Удалить товар из заказа (в 1С)" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={returnProductClicked}>
                <ListItemText primary="2) Сообщить магазину о возврате" />
              </ListItemButton>
            </ListItem>
          </List>
          <DialogActions>
            <Button onClick={() => setShowTypeAlert(false)}>Отмена</Button>
          </DialogActions>
        </Dialog>
        <Tooltip placement="left" title="Выбрать тип возврата">
          <IconButton sx={{ ml: 1 }} onClick={selectReturnTypeClicked}>
            <ProductionQuantityLimitsOutlinedIcon
              style={{ color: "#ff731a" }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  } else {
    return <></>;
  }
};
