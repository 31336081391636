import React from "react";
import { Box } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useEffect, useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $chatOrders } from "store/chat/orders";

const CheckEntityPaymentModal = observer(() => {
  const order = $modals.params;

  const [btnLoading, setBtnLoading] = useState(true);
  const [result, setResult] = useState(true);

  const checkPayment = () => {
    setBtnLoading(true);
    $chatOrders
      .checkEntityPayment(order.id)
      .then((res) => {
        setResult(res.payment_status);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    checkPayment();
  }, []);

  return (
    <Modal
      title="Проверка платежа юр.лица"
      subheader={btnLoading ? "Проверяем платеж..." : result}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          onClick={checkPayment}
          loading={btnLoading}
          variant="contained"
        >
          Проверить платеж
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default CheckEntityPaymentModal;
