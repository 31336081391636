import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { Modal as MuiModal } from "@mui/material";
import { isMobile } from "react-device-detect";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${isMobile ? "95%" : "500px"};
  background: #ffffff;
  border-radius: 12px;
  padding: 22px;
`;

const IconButtonSt = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const Modal = observer(({ children, title, subheader }) => {
  return (
    <MuiModal
      style={{ outline: "none" }}
      open
      onClose={() => $modals.close()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ContentWrapper>
        <IconButtonSt onClick={() => $modals.close()}>
          <CloseIcon />
        </IconButtonSt>
        <Typography sx={{ mb: 3 }} variant="h3" component="h2">
          {title}
        </Typography>
        {subheader && (
          <Typography sx={{ mb: 3 }} component="div">
            {subheader}
          </Typography>
        )}
        <Box>{children}</Box>
      </ContentWrapper>
    </MuiModal>
  );
});
