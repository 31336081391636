import { types } from "mobx-state-tree";
import { flow } from "mobx";

export const $reviews = types
  .model({
    reviews: types.frozen(null),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .views((self) => ({
    // get chartCategories() {
    //   return self.stats.map((el) => el.date);
    // },
  }))
  .actions((self) => ({
    fetchReviews: flow(function* (type) {
      // if (type !== "paginate") {
      //   self.resetPaginate();
      //   self.setComplaints([]);
      // }
      // try {
      //   $spinner.show();
      //   const res = yield api(v3Endpoint)
      //     .get(`consultant/complaints`, {
      //       params: {
      //         date: self.date,
      //         limit: self.limit,
      //         offset: self.offset,
      //       },
      //     })
      //     .then((res) => res.data);
      //   self.setHasMore(res.complaints.length >= self.limit);
      //
      //   self.setComplaints(
      //     type === "paginate"
      //       ? union(self.complaintsMessages, res.complaints)
      //       : res.complaints
      //   );
      //   self.setStats(res.stats);
      // } catch (err) {
      //   console.error("fetch complaints list: ", err);
      //   throw err;
      // } finally {
      //   $spinner.hide();
      // }
    }),
    remove: flow(function* (type) {
      // try {
      //   $spinner.show();
      //   const res = yield api(v3Endpoint)
      //     .get(`consultant/complaints`, {
      //       params: {
      //         date: self.date,
      //         limit: self.limit,
      //         offset: self.offset,
      //       },
      //     })
      //     .then((res) => res.data);
      //   self.setHasMore(res.complaints.length >= self.limit);
      //
      //   self.setComplaints(
      //     type === "paginate"
      //       ? union(self.complaintsMessages, res.complaints)
      //       : res.complaints
      //   );
      //   self.setStats(res.stats);
      // } catch (err) {
      //   console.error("fetch complaints list: ", err);
      //   throw err;
      // } finally {
      //   $spinner.hide();
      // }
    }),
    accept: flow(function* (type) {
      // try {
      //   $spinner.show();
      //   const res = yield api(v3Endpoint)
      //     .get(`consultant/complaints`, {
      //       params: {
      //         date: self.date,
      //         limit: self.limit,
      //         offset: self.offset,
      //       },
      //     })
      //     .then((res) => res.data);
      //   self.setHasMore(res.complaints.length >= self.limit);
      //
      //   self.setComplaints(
      //     type === "paginate"
      //       ? union(self.complaintsMessages, res.complaints)
      //       : res.complaints
      //   );
      //   self.setStats(res.stats);
      // } catch (err) {
      //   console.error("fetch complaints list: ", err);
      //   throw err;
      // } finally {
      //   $spinner.hide();
      // }
    }),
    setReviews(value) {
      self.complaintsMessages = value;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchReviews("paginate");
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLimit(data) {
      self.limit = data;
    },
  }))
  .create();
