import React from "react";
import { Box, TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { $tags } from "store/tags/tags";
import { $products } from "store/tags/productsInTags";
import { LoadingButton } from "@mui/lab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DELETE_TAG_MODAL } from "ui-components/modal/modals";

const EditTagModal = observer(() => {
  const [name, setName] = useState($modals.params.value);
  const [error, setError] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const deleteTag = () => {
    $modals.show(DELETE_TAG_MODAL, $products.currentTagId);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setError(null);
    $tags
      .editTag($modals.params, name)
      .then(() => {
        $tags.fetchTagsList("fetchAll");
        $modals.close();
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data[0].message);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Редактировать группу">
      <form onSubmit={onSubmit}>
        <TextField
          required
          error={!!error}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Название"
          variant="outlined"
          value={name}
          helperText={error}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <LoadingButton
            variant="contained"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={deleteTag}
          >
            Удалить группу
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={onSubmit}
            loading={btnLoading}
          >
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default EditTagModal;
