import React from "react";
import {Modal} from "ui-components/modal";
import {Button} from "@mui/material";
import {$modals} from "store/modals";
import {useState} from "react";
import {$customerActions} from "store/customers/actions";
import {observer} from "mobx-react-lite";
import {getDeleteLabel, getIsDeleted} from "lib/userStatuses";

const DeleteCustomerModal = observer(() => {

  const user = $modals.params
  const isDeleted = getIsDeleted(user)
  const [btnLoading, setBtnLoading] = useState(false)

  const restoreUser = () => {
    setBtnLoading(true)
    $customerActions.restore(user.id)
      .finally(() => {
        setBtnLoading(false)
        $modals.close()
      })
  }

  const deleteUser = () => {
    setBtnLoading(true)
    $customerActions.delete(user.id)
      .finally(() => {
        setBtnLoading(false)
        $modals.close()
      })
  }

  return (
    <Modal title={getDeleteLabel(user)}
           subheader={`Вы действительно хотите ${getDeleteLabel(user).toLowerCase()} покупателя?`}
    >
      <Button sx={{float: 'right'}}
              disabled={btnLoading}
              variant="contained"
              onClick={() => isDeleted ? restoreUser() : deleteUser()}>
        {getDeleteLabel(user)}
      </Button>
    </Modal>
  )
})

export default DeleteCustomerModal;