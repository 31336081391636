import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { InputSearch } from "ui-components/inputSearch";
import { debounce } from "lodash";
import { $remarks } from "store/remarks/remarks";
import RemarksTable from "./table";
import { $sidebar } from "store/sidebar";

const Remarks = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Ошибки в товарах");
    $remarks.fetchList();
  }, []);

  const handleSearch = debounce((e) => {
    $remarks.setSearchValue(e.target.value);
  }, 500);

  return (
    <>
      <InputSearch
        onChange={handleSearch}
        placeholder="Номер товара или штрихкод"
      />

      <Box sx={{ my: 2, marginLeft: "3px" }}>
        <FormControlLabel
          sx={{ color: "#424242" }}
          control={
            <Switch
              checked={$remarks.all}
              onChange={(e) => $remarks.setAll(e.target.checked)}
              name="blocked"
            />
          }
          label="Показывать уже исправленные"
        />
      </Box>
      <RemarksTable />
    </>
  );
});

export default Remarks;
