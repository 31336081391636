import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import React from "react";
import styled from "@emotion/styled";

const ImagePreviewContainer = styled.div`
  display: flex;

  img {
    transition: all 0.1s ease-in-out;
    width: 100%;
    border-radius: 3px;
    max-width: 250px;
    height: 100%;
  }

  .zoom-icon {
    transition: all 0.1s ease-in-out;
    opacity: 0;
    color: #fff;
    width: 36px;
    height: 36px;
  }

  .relative-container {
    position: relative;

    &:hover {
      img {
        filter: brightness(70%);
      }

      .zoom-icon {
        opacity: 1;
      }
    }

    .dark-box {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 100%;
      z-index: 10;
      width: 100%;
    }
  }
`;

export const ImagePreview = ({ url }) => {
  const imageClicked = (e) => {
    e.stopPropagation();
    window.open(url, "_blank").focus();
  };

  return (
    <ImagePreviewContainer>
      <div className="relative-container">
        <div className="dark-box" onClick={imageClicked}>
          <ZoomInOutlinedIcon className="zoom-icon" />
        </div>
        <img src={url} alt="" />
      </div>
    </ImagePreviewContainer>
  );
};
