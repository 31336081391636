import React from "react";
import { Box, TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $chatOrders } from "store/chat/orders";

const DeleteProductFromOrderModal = observer(() => {
  const product = $modals.params;

  const [count, setCount] = useState(product.pivot.amount);
  const [btnLoading, setBtnLoading] = useState(false);

  const onAmountChange = (e) => {
    if (e.target.value <= product.pivot.amount) {
      setCount(e.target.value);
    }
  };

  const onSubmit = () => {
    setBtnLoading(true);
    $chatOrders
      .deleteProductFromOrder(product, count)
      .then(() => {
        $chatOrders.getOrders();
        $modals.close();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  return (
    <Modal
      title={`Удаление товара из заказа №${product.pivot.order_id}`}
      subheader={product.name}
    >
      <TextField
        label="Количество"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        onChange={onAmountChange}
        value={count}
      />
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          disabled={count == 0}
          loading={btnLoading}
          variant="contained"
          onClick={onSubmit}
        >
          Удалить
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default DeleteProductFromOrderModal;
