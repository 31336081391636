import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { $promo } from "store/promo";
import React from "react";
import { observer } from "mobx-react-lite";
import { parseUnixTime } from "lib/features";
import { InfiniteScroll } from "ui-components/scrollingTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { $modals } from "store/modals";
import { EDIT_PROMO_MODAL } from "ui-components/modal/modals";
import { DELETE_PROMO_MODAL } from "ui-components/modal/modals";

const columns = [
  {
    id: "id",
    label: "id",
  },
  {
    id: "name",
    label: "Обложка",
  },
  {
    id: "img",
    label: "Название",
  },
  {
    id: "network",
    label: "Сеть",
  },
  {
    id: "main",
    label: "Основной",
  },
  {
    id: "begin",
    label: "Начало",
  },
  {
    id: "end",
    label: "Окончание",
  },
  {
    id: "actions",
    label: "Действия",
  },
];

const onEditPromo = (promo) => {
  $modals.show(EDIT_PROMO_MODAL, promo);
};
const deletePromo = (id) => {
  $modals.show(DELETE_PROMO_MODAL, id);
};

const PromoTable = observer(() => {
  return (
    <InfiniteScroll hasMore={false}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$promo.promoList.length > 0 &&
            $promo.promoList.map((promo, key) => {
              return (
                <TableRow hover tabIndex={-1} key={key}>
                  <TableCell>{promo.id}</TableCell>
                  <TableCell>
                    {promo.image_url && (
                      <Box
                        component="img"
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: "50%",
                        }}
                        alt="img"
                        src={promo.image_url}
                      />
                    )}
                  </TableCell>
                  <TableCell>{promo.name}</TableCell>
                  <TableCell>{promo.network.name}</TableCell>
                  <TableCell>{promo.main === 1 ? "Да" : "Нет"}</TableCell>
                  <TableCell>{parseUnixTime(promo.promo_begin_at)}</TableCell>
                  <TableCell>{parseUnixTime(promo.promo_end_at)}</TableCell>
                  <TableCell>
                    <Tooltip title="Редактировать">
                      <IconButton onClick={() => onEditPromo(promo)}>
                        <EditOutlinedIcon color="info" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить">
                      <IconButton onClick={() => deletePromo(promo.id)}>
                        <DeleteOutlineOutlinedIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default PromoTable;
