import {types} from "mobx-state-tree";
import {api, noPrefixEndpoint} from "../api";
import {flow} from "mobx";
import {$spinner} from "./spinner";
import {$snackbar} from "./snackbar";

export const $promo = types
  .model("Promo", {
    promoList: types.frozen([]),
    imageUrl: types.optional(types.string, '')
  })
  .actions((self) => ({
    fetchPromoList: flow(function* () {
      try {
        $spinner.show()
        const res = yield api(noPrefixEndpoint).get(`admin/promo/catalogs`).then(res => res.data)
        self.setPromo(res);
      } catch (err) {
        console.error("fetch promo list: ", err);
        throw err;
      }
      $spinner.hide()
    }),

    setPromo(data) {
      self.promoList = data
      console.log(self.promoList)
    },

    setImageUrl(data) {
      self.imageUrl = data
    },

    sendImage: flow(function* (image) {
        const data = new FormData();
        data.append('image', image);
        data.append('path', '/promo/catalog/');
        try {
          const res = 
          yield api(noPrefixEndpoint).post('admin/image/upload', data)
          .then(res => res.data)
          self.setImageUrl(res.url)
        } catch (err) {
          $snackbar.show('Ошибка загрузки фото')
          throw err;
        }
    }),
    
    editPromo: flow(function* (id, image_url, main, name, promo_begin_at, promo_end_at, promo_network_id, status) {
        try {
          yield api(noPrefixEndpoint).post(`admin/promo/catalogs`, {
            id:id,  
            image_url: self.imageUrl,
            main: main,
            name: name,
            promo_begin_at: promo_begin_at,
            promo_end_at: promo_end_at,
            promo_network_id: promo_network_id,
            status: 1
          })
        } catch (err) {
          $snackbar.show('Ошибка сохранения промо акции')
          throw err;
        }
    }),

    savePromo: flow(function* (image_url, main, name, promo_begin_at, promo_end_at, promo_network_id) {
        try {
          yield api(noPrefixEndpoint).post(`admin/promo/catalogs`, {
            image_url: self.imageUrl,
            main: main,
            name: name,
            promo_begin_at: promo_begin_at,
            promo_end_at: promo_end_at,
            promo_network_id: promo_network_id
          })
        } catch (err) {
          $snackbar.show('Ошибка сохранения промо акции')
          throw err;
        }
    }),
      
    deletePromo: flow(function* (id) {
        try {
          $spinner.show()
          yield api(noPrefixEndpoint)
           .delete(`admin/promo/catalogs/${id}`)
           .then(res => res.data)
        } catch (err) {
          console.error("fetch promo list: ", err);
          throw err;
        }
        $spinner.hide();
    })
  }))
.create();


export const $networks = types
  .model("Networks", {
    networksList: types.frozen([]),
  })
  .actions((self) => ({
    fetchNetworksList: flow(function* () {
      try {
        $spinner.show()
        const res = yield api(noPrefixEndpoint).get(`admin/promo/networks`).then(res => res.data)
        self.setPromo(res);
      } catch (err) {
        console.error("fetch networks list: ", err);
        throw err;
      }
      $spinner.hide();
    }),
    setPromo(data) {
      self.networksList = data
    },
  }))
.create();
