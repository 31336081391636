import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { $sidebar } from "store/sidebar";
import NotificationsTable from "./table";
import { $notifications } from "../../store/notifications/notifications";
import { numberWithSpaces } from "../../lib/features";

const Notifications = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Уведомления");
    $notifications.fetchList();
  }, []);

  return (
    <>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Уведомления
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        Всего уведомлений: {numberWithSpaces($notifications.count)}
      </Box>
      <NotificationsTable />
    </>
  );
});

export default Notifications;
