import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "api";
import { flow } from "mobx";
import { $spinner } from "../spinner";

export const $roles = types
  .model({
    roles: types.frozen([]),
  })
  .actions((self) => ({
    fetchRoles: flow(function* (params, withTrashed = false) {
      try {
        self.setRoles([]);
        !params?.hideSpinner && $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`roles`, {
            params: {
              withTrashed: withTrashed ? 1 : 0,
            },
          })
          .then((res) => res.data);
        self.setRoles(res);
        $spinner.hide();
      } catch (err) {
        $spinner.hide();
        throw err;
      }
    }),
    createRole: flow(function* (data) {
      try {
        yield api(v3Endpoint)
          .post(`roles`, data)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    editRole: flow(function* (roleName, data) {
      try {
        yield api(v3Endpoint)
          .put(`roles/${roleName}`, data)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    deleteRole: flow(function* (roleName) {
      try {
        yield api(v3Endpoint).delete(`roles/${roleName}`);
      } catch (err) {
        throw err;
      }
    }),
    restoreRole: flow(function* (roleName) {
      try {
        yield api(v3Endpoint).post(`roles/${roleName}/restore`);
      } catch (err) {
        throw err;
      }
    }),
    setRoles(data) {
      self.roles = data;
    },
  }))
  .create();
