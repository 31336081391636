import { types } from "mobx-state-tree";

export const $common = types
  .model("Common", {
    currentRoute: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setCurrentRoute(value) {
      self.currentRoute = value;
    },
  }))
  .create();
