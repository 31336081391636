import { types } from "mobx-state-tree";
import { api, consultantEndpoint } from "api";
import { flow } from "mobx";
import { $spinner } from "../spinner";

export const $suggestions = types
  .model("Suggestions", {
    categories: types.frozen([]),
  })
  .actions((self) => ({
    fetchCategories: flow(function* () {
      try {
        $spinner.show();
        const res = yield api(consultantEndpoint)
          .get(`admin/suggestions/categories`)
          .then((res) => res.data);
        self.setCategories(res);
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    editCategory: flow(function* (name, id) {
      try {
        yield api(consultantEndpoint).put(
          `admin/suggestions/categories/${id}`,
          {
            name: name,
          }
        );
      } catch (err) {
        throw err;
      }
    }),
    createCategory: flow(function* (name) {
      try {
        yield api(consultantEndpoint).post("admin/suggestions/categories", {
          name: name,
        });
      } catch (err) {
        throw err;
      }
    }),
    deleteCategory: flow(function* (id) {
      try {
        yield api(consultantEndpoint).delete(
          `admin/suggestions/categories/${id}`
        );
      } catch (err) {
        throw err;
      }
    }),

    //messages
    createSuggestion: flow(function* (params) {
      try {
        yield api(consultantEndpoint).post(`admin/suggestions/messages`, {
          text: params.suggestion,
          category_id: params.category,
        });
      } catch (err) {
        throw err;
      }
    }),
    editSuggestion: flow(function* (params) {
      try {
        yield api(consultantEndpoint).put(
          `admin/suggestions/messages/${params.id}`,
          {
            text: params.suggestion,
            category_id: params.category,
          }
        );
      } catch (err) {
        throw err;
      }
    }),
    deleteSuggestion: flow(function* (id) {
      try {
        yield api(consultantEndpoint).delete(
          `admin/suggestions/messages/${id}`
        );
      } catch (err) {
        throw err;
      }
    }),
    setCategories(data) {
      self.categories = data;
    },
  }))
  .create();
