import { InfiniteScroll } from "ui-components/scrollingTable";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { $suggestions } from "store/suggestions/suggestions";
import { observer } from "mobx-react-lite";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { $modals } from "store/modals";
import {
  DELETE_CATEGORY_MODAL,
  DELETE_SUGGESTION_MODAL,
  EDIT_CATEGORY_MODAL,
  EDIT_SUGGESTION_MODAL,
} from "ui-components/modal/modals";

const columns = [
  {
    id: "name",
    label: "Категория",
  },
  {
    id: "actions",
    label: "Действия",
    align: "right",
  },
];

const ActionsTableCell = ({ onEdit, onDelete }) => {
  return (
    <TableCell component="td" align="right" sx={{ minWidth: 130 }}>
      <IconButton onClick={onEdit}>
        <EditOutlinedIcon color="info" />
      </IconButton>
      <IconButton onClick={onDelete}>
        <DeleteOutlineOutlinedIcon color="error" />
      </IconButton>
    </TableCell>
  );
};

const SuggestionsTable = observer(() => {
  const [selectedCategoryId, setCategoryId] = useState(null);

  const onDeleteCategory = (category) => {
    $modals.show(DELETE_CATEGORY_MODAL, category);
  };

  const onEditCategory = (category) => {
    $modals.show(EDIT_CATEGORY_MODAL, category);
  };

  const onDeleteSuggestion = (suggestion) => {
    $modals.show(DELETE_SUGGESTION_MODAL, suggestion);
  };

  const onEditSuggestion = (suggestion) => {
    $modals.show(EDIT_SUGGESTION_MODAL, suggestion);
  };

  return (
    <InfiniteScroll hasMore={false}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                colSpan={2}
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, fontWeight: 600 }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$suggestions.categories.map((category, key) => (
            <Fragment key={key}>
              <TableRow
                sx={
                  selectedCategoryId === category.id
                    ? {
                        "& > td": {
                          borderColor: "transparent",
                          background: "#e8e8e8",
                        },
                      }
                    : {}
                }
                hover
                tabIndex={-1}
                key={key}
              >
                <TableCell sx={{ width: 50 }}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setCategoryId(
                        selectedCategoryId === category.id ? null : category.id
                      )
                    }
                  >
                    {selectedCategoryId === category.id ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{category.name}</TableCell>
                <ActionsTableCell
                  onDelete={() => onDeleteCategory(category)}
                  onEdit={() => onEditCategory(category)}
                />
              </TableRow>
              <TableRow>
                <TableCell sx={{ p: 0, border: 0 }} colSpan={3}>
                  <Collapse
                    in={category.id === selectedCategoryId}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ mb: 2, background: "#f5f5f5" }}>
                      {category.messages.length > 0 ? (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 600 }}>
                                Сообщение
                              </TableCell>
                              <TableCell sx={{ fontWeight: 600 }} align="right">
                                Действия
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {category.messages.map((message, key) => (
                              <TableRow key={key}>
                                <TableCell component="td">
                                  {message.text}
                                </TableCell>
                                <ActionsTableCell
                                  onDelete={() => onDeleteSuggestion(message)}
                                  onEdit={() => onEditSuggestion(message)}
                                />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <Box sx={{ p: 2, textAlign: "center" }}>
                          Нет сообщений
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default SuggestionsTable;
