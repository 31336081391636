import React from "react";
import { Box } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $suggestions } from "store/suggestions/suggestions";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";

const DeleteSuggestionModal = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);
  const suggestion = $modals.params;

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    $suggestions
      .deleteSuggestion(suggestion.id)
      .then(() => {
        $suggestions.fetchCategories();
        $modals.close();
      })
      .catch((err) => console.log(err.response))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal
      title="Удаление сообщения"
      subheader={
        <>
          Вы действительно хотите удалить сообщение <b>{suggestion.value}</b> ?
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <Box sx={{ textAlign: "right" }}>
          <LoadingButton loading={btnLoading} variant="contained" type="submit">
            Удалить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default DeleteSuggestionModal;
