import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { $sidebar } from "store/sidebar";
import { NotificationExamples } from "./preview";
import { $notificationForm } from "../../store/notifications/notificationForm";
import iziToast from "izitoast";
import { Filters } from "./filters";
import { Alert, LoadingButton } from "@mui/lab";
import "./styles.scss";
import { isDesktop } from "react-device-detect";

const NotificationsForm = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    $sidebar.setPageTitle("Создать рассылку");
  }, []);

  useEffect(() => {
    iziToast.warning({
      title: "Раздел в разработке",
    });

    $notificationForm.getFilters();
    $notificationForm.calculateUsersCount();
  }, []);

  const sendClicked = (e) => {
    e.preventDefault();
    setErrors({});
    if ($notificationForm.usersCount === 0) {
      return iziToast.warning({
        title: "Выбрано 0 пользователей",
      });
    }
    setBtnLoading(true);
    $notificationForm
      .send()
      .then(() => {
        iziToast.success({
          title: "Рассылка в обработке",
        });
      })
      .catch((err) => {
        if (err.response.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <div className="create-notification-page">
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Создать рассылку
      </Typography>

      <Filters />
      <form onSubmit={sendClicked}>
        <div className="main-form">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={{ mb: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <div
                    className="selected-users-label"
                    style={{ position: "relative", height: "48px" }}
                  >
                    <Alert severity="info" className="alert-count">
                      Пользователей выбрано:{" "}
                      <b>{$notificationForm.usersCount}</b>
                    </Alert>
                    {$notificationForm.calculateLoading && (
                      <LinearProgress
                        style={{
                          position: "absolute",
                          width: "100%",
                          bottom: 0,
                        }}
                      />
                    )}
                  </div>
                </Box>
                <TextField
                  onChange={(e) => $notificationForm.setHeader(e.target.value)}
                  fullWidth
                  label="Заголовок"
                  variant="outlined"
                  required
                  error={!!errors.header}
                  helperText={errors.header}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <TextField
                  onChange={(e) => $notificationForm.setText(e.target.value)}
                  fullWidth
                  label="Сообщение"
                  rows={5}
                  multiline
                  required
                  error={!!errors.text}
                  helperText={errors.text}
                />
              </Box>
              <Box>
                //todo прикреплять вложения
                {/*<AttachmentSelect />*/}
              </Box>
            </Grid>
            {isDesktop && (
              <Grid item xs={12} md={5}>
                <NotificationExamples
                  header={$notificationForm.header}
                  text={$notificationForm.text}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <Box className="flex-end" sx={{ mt: 2 }}>
          <LoadingButton
            disabled
            loading={btnLoading}
            type="submit"
            variant="contained"
          >
            Отправить
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
});

export default NotificationsForm;
