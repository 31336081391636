import React from "react";
import { Modal } from "ui-components/modal";
import { $modals } from "store/modals";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
import { $users } from "store/users/users";

const DeleteUserModal = observer(() => {
  const user = $modals.params;
  const [btnLoading, setBtnLoading] = useState(false);

  const deleteUser = () => {
    setBtnLoading(true);
    $users
      .delete(user.id)
      .then(() => $users.fetchList())
      .finally(() => {
        setBtnLoading(false);
        $modals.close();
      });
  };

  return (
    <Modal
      title="Удалить"
      subheader="Вы действительно хотите удалить пользователя?"
    >
      <Alert sx={{ mb: 3 }} severity="info">
        Пользователь потеряет доступ к этому сайту. <br />
        Но останется в разделе <strong>Покупатели</strong>
      </Alert>

      <LoadingButton
        sx={{ float: "right" }}
        loading={btnLoading}
        variant="contained"
        onClick={deleteUser}
      >
        Удалить
      </LoadingButton>
    </Modal>
  );
});

export default DeleteUserModal;
