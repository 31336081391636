import { IconButton, Tooltip } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import React from "react";

export const ImagePreview = ({ onDelete, previewUrl }) => {
  return (
    <div style={{ padding: "8px 16px", display: "flex" }}>
      <div style={{ position: "relative" }}>
        <Tooltip placement="top" title="Не прикреплять">
          <IconButton
            size="small"
            onClick={onDelete}
            style={{
              position: "absolute",
              right: "0",
              background: "rgba(77,76,76,0.6)",
              borderRadius: "2px",
              width: "20px",
              height: "20px",
            }}
          >
            <ClearOutlinedIcon style={{ color: "#fff", width: "16px" }} />
          </IconButton>
        </Tooltip>
        <img
          style={{ height: "100%", width: "100px" }}
          src={previewUrl}
          alt=""
        />
      </div>
    </div>
  );
};
