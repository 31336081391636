import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { api, v3Endpoint } from "../../api";

export const $textarea = types
  .model({
    isEditMode: types.optional(types.boolean, false),
    value: types.optional(types.string, ""),
    loading: types.optional(types.boolean, false),
    previewUrl: types.optional(types.string, ""),
    uploadedFileName: types.optional(types.string, ""),
    file: types.frozen(null),
    emojiVisible: types.frozen(false),
  })
  .views((self) => ({
    get isSendingDisabled() {
      return self.loading || (self.value.length === 0 && !self.file);
    },
  }))
  .actions((self) => ({
    sendMessage: flow(function* (data) {
      try {
        const formData = new FormData();

        Object.entries(data).map((el) => {
          if (el[1]) formData.append(el[0], el[1]);
        });

        yield api(v3Endpoint)
          .post(`consultant/${data.customer_id}/messages`, formData)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    setValue(value) {
      self.value = value ? value : "";
    },
    setLoading(value) {
      self.loading = value;
    },
    appendValue(value) {
      self.value += " " + value;
    },
    appendValueNoSpace(value) {
      self.value += value;
    },
    setEditMode(value) {
      self.isEditMode = value;
    },
    setPreviewUrl(value) {
      self.previewUrl = value;
    },
    setUploadedFileName(value) {
      self.uploadedFileName = value;
    },
    setFile(value) {
      self.file = value;
    },
    clearAttachment() {
      self.uploadedFileName = "";
      self.previewUrl = "";
      self.file = null;
    },
    toggleEmoji() {
      self.emojiVisible = !self.emojiVisible;
    },
  }))
  .create();
