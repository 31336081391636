import React from "react";
import { Box } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { $tags } from "store/tags/tags";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $products } from "store/tags/productsInTags";

const DeleteTagModal = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = () => {
    setBtnLoading(true);
    $tags
      .deleteTag($modals.params)
      .then(() => {
        $tags.fetchTagsList("fetchAll");
        $products.setProductsInTagList([]);
      })
      .finally(() => {
        setBtnLoading(false);
        $modals.close();
      });
  };

  return (
    <Modal
      title="Удаление группы"
      subheader={
        <>
          Вы действительно хотите удалить группу<b>{$modals.params.value}</b>?
        </>
      }
    >
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          loading={btnLoading}
          variant="contained"
          onClick={onSubmit}
        >
          Удалить
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default DeleteTagModal;
