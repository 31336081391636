import { Box, TextField, LinearProgress, Tabs, Tab } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { InfiniteScroll } from "../../ui-components/scrollingTable";
import { $chatRooms } from "../../store/chat/rooms";
import { $user } from "../../store/user";
import { ChatListItem } from "./components/chatListItem";
import { $draft } from "../../store/chat/draft";
import { $textarea } from "../../store/chat/textarea";
import { $chatOrders } from "../../store/chat/orders";
import { debounce } from "lodash";

const Container = styled.div`
  border-right: 1px solid #e7e8ea;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 26%;
  min-width: 380px;
  overflow: hidden;
  transition: all 0.05s ease-in-out;
  position: relative;

  .wrapper {
    height: 49px;
    border-bottom: 1px solid #e7e8ea;
    display: flex;
    justify-content: space-between;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 28px 0;
`;

const CardsWrapper = styled.div`
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const TextFieldSt = styled(TextField)`
  padding-top: 2px;
  padding-left: 30px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:before {
    content: "+7";
    position: absolute;
    padding-top: 13px;
    margin-left: -6px;
    font-weight: 500;
    font-size: 16px;
  }

  * {
    font-size: 16px !important;
    background: transparent !important;
  }
`;

export const ChatList = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  //Если указать в ссылке ?customer_id=123, то можно делиться ссылкой на определенный чат
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let customerId = parseInt(params.get("customer_id"));
    if (customerId) {
      $chatRooms.setSelectedCustomerId(customerId);
      $chatOrders.getOrders();
    }
  }, []);

  const handleSearch = debounce(function (e) {
    $chatRooms.fetchRooms({ search: e.target.value });
  }, 1000);

  const rooms = $chatRooms.searchResultRooms
    ? $chatRooms.searchResultRooms
    : $chatRooms.rooms;

  const chatCardClicked = (userId, draft) => {
    if (userId === $chatRooms.selectedCustomerId) return;

    $chatOrders.setUser([]);

    //при переходе в другой диалог создаем черновик, если есть
    if ($textarea.value.length > 0) {
      $draft.create($textarea.value);
    }

    $textarea.setValue(draft ? draft : "");

    navigate({ pathname: `/chat?customer_id=${userId}` });
    $chatRooms.setSelectedCustomerId(userId);
    $chatOrders.getOrders();
  };

  return (
    <Container>
      <Box className="wrapper">
        {$chatRooms.showSearch ? (
          <TextFieldSt
            autoFocus
            fullWidth
            onChange={handleSearch}
            size="small"
          />
        ) : (
          !$user.isCourier && (
            <div>
              <Tabs
                value={$chatRooms.filter}
                onChange={(e, newValue) => $chatRooms.setFilter(newValue)}
              >
                <Tab
                  style={{ fontSize: "15px" }}
                  value="all"
                  label="Все диалоги"
                />
                <Tab
                  style={{ fontSize: "15px" }}
                  value="important"
                  label={
                    <div className="flex">
                      Важные
                      {$chatRooms.importantCount > 0 && (
                        <div
                          style={{
                            width: "20px",
                            marginLeft: "2px",
                            color: "#ffa500",
                            fontSize: "15px",
                          }}
                        >
                          {$chatRooms.importantCount}
                        </div>
                      )}
                    </div>
                  }
                />
                <Tab
                  style={{ fontSize: "15px" }}
                  value="delivery"
                  label="Доставка"
                />
              </Tabs>
            </div>
          )
        )}

        <div style={{ padding: "4px" }}>
          {$chatRooms.showSearch ? (
            <IconButton
              onClick={() => {
                $chatRooms.setSearchResultRooms(null);
                $chatRooms.setShowSearch(false);
              }}
            >
              <CloseOutlinedIcon style={{ color: "#ccc" }} />
            </IconButton>
          ) : (
            <IconButton onClick={() => $chatRooms.setShowSearch(true)}>
              <SearchOutlinedIcon style={{ color: "#ccc" }} />
            </IconButton>
          )}
        </div>
      </Box>
      {$chatRooms.loading && (
        <LinearProgress
          style={{
            position: "absolute",
            zIndex: 1000,
            width: "100%",
            left: 0,
          }}
        />
      )}
      <CardsWrapper>
        <InfiniteScroll
          style={{ minHeight: "100%", height: "100%" }}
          isTable={false}
          hasMore={$chatRooms.hasMore && !$chatRooms.searchResultRooms}
          onScroll={() => $chatRooms.paginate()}
        >
          {rooms.map((room, key) => (
            <ChatListItem
              chatCardClicked={chatCardClicked}
              key={key}
              room={room}
            />
          ))}
        </InfiniteScroll>
      </CardsWrapper>
    </Container>
  );
});
