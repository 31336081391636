import React from "react";
import { Modal } from "ui-components/modal";
import { Button } from "@mui/material";
import { $modals } from "store/modals";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { $chatOrders } from "store/chat/orders";

const CheckPaymentModal = observer(() => {
  const order = $modals.params;

  const [btnLoading, setBtnLoading] = useState(false);

  const checkClicked = () => {
    setBtnLoading(true);
    $chatOrders.checkPayment(order.id).finally(() => {
      setBtnLoading(false);
    });
  };

  return (
    <Modal
      title="Проверка оплаты"
      subheader={`Проверить статус оплаты заказа №${order.id}`}
    >
      <Button
        sx={{ float: "right" }}
        disabled={btnLoading}
        variant="contained"
        onClick={checkClicked}
      >
        Проверить
      </Button>
    </Modal>
  );
});

export default CheckPaymentModal;
