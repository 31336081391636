import { types } from "mobx-state-tree";
import { api } from "api";
import { flow } from "mobx";
import { $snackbar } from "../snackbar";
import { union } from "lodash";
import { $spinner } from "../spinner";

export const $remarks = types
  .model("Remarks", {
    list: types.frozen([]),
    searchValue: types.optional(types.string, ""),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    all: types.optional(types.boolean, false),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setRemarks([]);
      }

      try {
        $spinner.show();
        const res = yield api()
          .get(`admin/remarks`, {
            params: {
              limit: self.limit,
              offset: self.offset,
              all: self.all,
              ...(self.searchValue && { query: self.searchValue }),
            },
          })
          .then((res) => res.data);

        self.setHasMore(res.length >= self.limit);

        self.setRemarks(type === "paginate" ? union(self.list, res) : res);
        $spinner.hide();
      } catch (err) {
        $spinner.hide();
        $snackbar.show("Ошибка получения списка");
        throw err;
      }
    }),
    correct: flow(function* (id) {
      try {
        $spinner.show();
        yield api().put(`admin/remarks?id=${id}`);
        $spinner.hide();
        self.fetchList();
      } catch (err) {
        $spinner.hide();
        $snackbar.show("Ошибка исправления ошибки");
        throw err;
      }
    }),
    paginate() {
      self.offset += self.limit;
      self.fetchList("paginate");
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setRemarks(data) {
      self.list = data;
    },
    setAll(value) {
      self.all = value;
      self.fetchList();
    },
    setSearchValue(value) {
      self.searchValue = value;
      self.fetchList();
    },
    resetPaginate() {
      self.offset = 0;
    },
  }))
  .create();
