import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "api";
import { flow } from "mobx";
import { $spinner } from "../spinner";
import { union } from "lodash";

export const $notifications = types
  .model({
    list: types.frozen([]),
    limit: types.optional(types.number, 100),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
    filter: types.optional(types.string, "all"),
    count: types.frozen(),
  })
  .actions((self) => ({
    fetchList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setList([]);
      }

      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`notifications/global`, {
            params: {
              limit: self.limit,
              offset: self.offset,
              filter: self.filter,
            },
          })
          .then((res) => res.data);

        self.setHasMore(res.notifications.length >= self.limit);
        self.setList(
          type === "paginate"
            ? union(self.list, res.notifications)
            : res.notifications
        );
        self.setTotalCount(res.count);
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    setList(data) {
      self.list = data;
    },
    resetPaginate() {
      self.offset = 0;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchList("paginate");
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setFilter(value) {
      self.filter = value;
      self.fetchList();
    },
    setTotalCount(value) {
      self.count = value;
    },
  }))
  .create();
