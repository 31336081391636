import {
  Avatar,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { getDeleteLabel } from "lib/userStatuses";
import { $modals } from "store/modals";
import { DELETE_USER_MODAL, EDIT_USER_MODAL } from "ui-components/modal/modals";
import { InfiniteScroll } from "ui-components/scrollingTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { $users } from "store/users/users";
import Chip from "@mui/material/Chip";

const columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "avatar",
    label: "Аватар",
  },
  {
    id: "name",
    label: "Имя",
  },
  {
    id: "role",
    label: "Роль",
  },
  {
    id: "phone",
    label: "Телефон",
  },
  {
    id: "email",
    label: "E-mail",
  },
  {
    id: "created_at",
    label: "Дата регистрации",
  },
  {
    id: "actions",
    label: "Действия",
    minWidth: "112px",
    align: "right",
  },
];

const UsersTable = observer(() => {
  const deleteUserClicked = (user) => {
    $modals.show(DELETE_USER_MODAL, user);
  };

  const editUserClicked = (user) => {
    $modals.show(EDIT_USER_MODAL, user);
  };

  return (
    <InfiniteScroll
      onScroll={(e) => $users.paginate()}
      hasMore={$users.hasMore}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$users.list.map((user, key) => (
            <TableRow hover tabIndex={-1} key={key}>
              <TableCell>{user.id}</TableCell>
              <TableCell>
                <Avatar alt={user.name} src={user.profile?.avatar} />
              </TableCell>
              <TableCell>{user.profile?.name}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  {user.roles.map((role, key) => (
                    <Chip
                      color={
                        role.item_name === "developer" ? "error" : "default"
                      }
                      key={key}
                      variant="outlined"
                      label={role.description}
                      size="small"
                    />
                  ))}
                </Stack>
              </TableCell>
              <TableCell>{user.phone}</TableCell>
              <TableCell>{user.email ? user.email : "-"}</TableCell>
              <TableCell>{user.created_at}</TableCell>
              <TableCell align="right">
                <Tooltip title="Редактировать">
                  <IconButton onClick={() => editUserClicked(user)}>
                    <EditOutlinedIcon color="info" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={getDeleteLabel(user)}>
                  <IconButton onClick={() => deleteUserClicked(user)}>
                    <DeleteOutlineOutlinedIcon color="error" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default UsersTable;
