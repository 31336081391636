import { Box, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { $notificationForm } from "../../store/notifications/notificationForm";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { $geo } from "../../store/geo/geo";
import { $store } from "../../store/store/store";
import { CustomSelect } from "./customSelect";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ru from "date-fns/locale/ru";

export const Filters = observer(() => {
  const onRegionChange = (params) => {
    if ($notificationForm.selectedCityIds.length > 0) {
      $notificationForm.setSelectedCityIds([]);
    }
    if ($notificationForm.selectedStoreIds.length > 0) {
      $notificationForm.setSelectedStoreIds([]);
    }
    $notificationForm.setSelectedRegionIds(params.map((el) => el.id));
  };

  const onCityChange = (params) => {
    if ($notificationForm.selectedStoreIds.length > 0) {
      $notificationForm.setSelectedStoreIds([]);
    }
    $notificationForm.setSelectedCityIds(params.map((el) => el.id));
  };

  const onStoreChange = (params) => {
    $notificationForm.setSelectedStoreIds(params.map((el) => el.id));
  };

  const onClearDatesClicked = () => {
    $notificationForm.setDates([null, null]);
    $notificationForm.calculateUsersCount();
  };

  const [key, setKey] = useState(1);
  return (
    <div className="push-filters-wrapper">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <CustomSelect
            label="Регион"
            selectedOptions={$notificationForm.selectedRegionIds}
            options={$geo.regions}
            onChange={onRegionChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomSelect
            label="Город"
            selectedOptions={$notificationForm.selectedCityIds}
            options={$geo.getFilteredCities(
              $notificationForm.selectedRegionIds
            )}
            onChange={onCityChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomSelect
            label="ПВЗ"
            selectedOptions={$notificationForm.selectedStoreIds}
            options={$store.getFilteredStores(
              $notificationForm.selectedCityIds
            )}
            onChange={onStoreChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
            <DateRangePicker
              showToolbar
              toolbarTitle="Выберите диапазон заказов клиента"
              disableFuture
              closeOnSelect
              onAccept={() => {
                setKey(Date.now());
                $notificationForm.calculateUsersCount();
              }}
              startText="Заказы от"
              endText="Заказы до"
              inputFormat="dd.MM.Y"
              value={$notificationForm.dates}
              onChange={(newValue) => $notificationForm.setDates(newValue)}
              renderInput={(startProps, endProps) => (
                <React.Fragment key={startProps}>
                  <TextField {...startProps} />
                  <Box>
                    <Tooltip title="Очистить даты">
                      <IconButton
                        onClick={onClearDatesClicked}
                        className="clear-dates-button"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </div>
  );
});
