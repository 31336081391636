import { observer } from "mobx-react-lite";
import React, { useEffect, useLayoutEffect } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { $customers } from "store/customers/customers";
import { isMobile } from "react-device-detect";
import CustomersTable from "./table";
import { numberWithSpaces } from "lib/features";
import { debounce } from "lodash";
import { InputSearch } from "ui-components/inputSearch";
import { $sidebar } from "store/sidebar";
import { $spinner } from "../../store/spinner";

const countBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  color: "#424242",
};

const Customers = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Покупатели");
    $customers.fetchList();
    $customers.fetchUsersStats();
  }, []);

  useLayoutEffect(() => {
    $spinner.show();
  }, []);

  const statsBox = (
    <Box sx={isMobile ? { mb: 2 } : {}}>
      <div style={countBoxStyle}>
        <Box sx={{ mr: 2 }}>Всего:</Box>{" "}
        {numberWithSpaces($customers.allUsersCount)}
      </div>
      <div style={countBoxStyle}>
        <Box sx={{ mr: 2 }}>Активных за день:</Box>{" "}
        {numberWithSpaces($customers.activeUsersCount)}
      </div>
    </Box>
  );

  const handleSearch = debounce((e) => {
    $customers.setSearchValue(e.target.value);
  }, 500);

  return (
    <>
      {isMobile && statsBox}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <InputSearch
          onChange={handleSearch}
          placeholder="Номер телефона или имя"
        />
        {!isMobile && statsBox}
      </Box>

      <Box sx={{ my: 2, marginLeft: "3px" }}>
        <FormControlLabel
          sx={{ color: "#424242" }}
          control={
            <Switch
              checked={$customers.showBlockedOnly}
              onChange={(e) => $customers.setShowBlockedOnly(e.target.checked)}
              name="blocked"
            />
          }
          label="Показать только заблокированных пользователей (покупки)"
        />
      </Box>
      <CustomersTable />
    </>
  );
});

export default Customers;
