import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Checkbox
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {useState, useRef} from "react";
import {$promo, $networks} from "store/promo";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {fromUnixTime, getUnixTime} from "date-fns";

const EditPromoModal = observer(() => {
  const [id] = useState($modals.params.id)
  const [imagePath, setImgPath] = useState('');
  const [name, setName] = useState($modals.params.name);
  const [promo_network_id, setPromoNetworkId] = useState($modals.params.promo_network_id);
  const [promo_begin_at, setBeginDate] = useState($modals.params.promo_begin_at);
  const [promo_end_at, setEndDate] = useState($modals.params.promo_end_at);
  const [btnLoading, setBtnLoading] = useState(false);
  const [main, setChecked] = useState($modals.params.main);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const imageInput = useRef(null);

  const onSubmit = () => {
    setBtnLoading(true)
    $promo.editPromo(id, imagePath, main, name, getUnixTime(promo_begin_at), getUnixTime(promo_end_at), promo_network_id)
      .then(() => {
        $promo.fetchPromoList()
        $modals.close()
      })
      .catch(err => {
        console.log(err.response);
      })
      .finally(() => setBtnLoading(false))
  }
  const onSelectImage = () => {
    imageInput.current.click();
  }
  const onChangeImage = (e) => {
    $promo.sendImage(e.target.files[0])
    setImgPath(e.target.files[0])
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal title="Изменить каталог">
        <TextField
          onChange={e => setName(e.target.value)}
          fullWidth sx={{mb: 3}}
          label="Название"
          variant="outlined"
          value={name}
        />
        <FormControl sx={{mb: 3}} fullWidth>
          <InputLabel id="edit-level">Торговая сеть</InputLabel>
          <Select
            labelId="edit-level"
            id="edit-level"
            label="Торговая сеть"
            value={promo_network_id}
            onChange={e => setPromoNetworkId(e.target.value)}
          >
            {$networks.networksList.map((network) => <MenuItem value={network.id}
              key={network.id}>{network.name}</MenuItem>)}
          </Select>
        </FormControl>
        {imagePath &&  <Box sx={{
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         padding: 5}}>
          <Box 
           component="img"
           sx={{
               width:300,
               height:300,
               top: '50%',
               left: '50%'}}
           alt = "img"
           src = {URL.createObjectURL(imagePath)}
          />        
        </Box>}
        <Stack direction='row' sx={{mb: 3}} spacing={1}>
          <FormControlLabel control={<Checkbox defaultChecked main={main} onChange={handleChange}/>} label="Основной"/>
          <input
            onChange={onChangeImage}
            type="file"
            accept="image/*"
            ref={imageInput}
            style={{display: 'none'}}
          />
          <Button
           variant="contained"
           fullWidth
           sx={{mb: 3}} 
           onClick = {onSelectImage}
          >
            Выберите изображение
          </Button>
        </Stack>
        <Stack direction='row' sx={{mb: 3}} spacing={1}>
          <DatePicker
            inputFormat="dd.MM.yyyy"
            label="Дата начала акции"
            renderInput={(params) => <TextField {...params} />}
            sx={{width: '100%'}}
            onChange={(newValue) => setBeginDate(newValue)}
            value={fromUnixTime(promo_begin_at)}
          />
          <DatePicker
            inputFormat="dd.MM.yyyy"
            label="Дата окончания акции"
            renderInput={(params) => <TextField {...params} />}
            sx={{width: '100%'}}
            onChange={(newValue) => setEndDate(getUnixTime(newValue))}
            value={fromUnixTime(promo_end_at)}
          />
        </Stack>

        <Box sx={{textAlign: 'right'}}>
          <Button
            disabled={btnLoading}
            variant="contained"
            onClick={onSubmit}
            fullWidth
          >
            Сохранить
          </Button>
        </Box>
      </Modal>
    </LocalizationProvider>
  )
});

export default EditPromoModal;