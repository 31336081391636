import { ImagePreview } from "../imagePreview";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import React from "react";

export const SimpleMessage = ({ message }) => {
  function linkify(text) {
    if (!text) return null;
    // Поиск ссылок в тексте
    const linkRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const newTextWithLinks = text.replace(
      linkRegex,
      '<a target="_blank" href="$1">ссылка</a>'
    );

    // Сохранение переносов строк
    return newTextWithLinks.replace(/\n/g, "<br>");
  }

  return (
    <>
      {!message.as_file && message.attachment_url && (
        //Фото
        <ImagePreview url={message.attachment_url} />
      )}

      {message.message && (
        //Сообщение
        <div
          style={{
            padding: "3px 0",
            width: "fit-content",
            cursor: "default",
            fontSize: "16px",
            wordBreak: "break-word",
          }}
          onClick={(e) => e.stopPropagation()}
          dangerouslySetInnerHTML={{ __html: linkify(message.message) }}
        />
      )}

      {message.as_file && (
        //Файл
        <a
          className="download-file"
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
          href={message.attachment_url}
        >
          <GetAppOutlinedIcon />
          <span>Скачать файл</span>
        </a>
      )}
    </>
  );
};
