import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $chatOrders } from "store/chat/orders";
import { orderStatuses } from "lib/orderStatuses";

const RestoreOrderModal = observer(() => {
  const orderId = $modals.params;

  const [btnLoading, setBtnLoading] = useState(false);
  const [newOrderStatus, setNewOrderStatus] = useState("");

  const onSubmit = () => {
    setBtnLoading(true);
    $chatOrders
      .restoreOrder(orderId, newOrderStatus)
      .then(() => $modals.close())
      .finally(() => setBtnLoading(false));
  };

  const getOrderStatuses = () => {
    return Object.entries(orderStatuses).filter(
      (el) => el[0] != 0 && el[0] != 7
    );
  };

  return (
    <Modal title="Восстановление заказа" subheader={`Заказ №${orderId}`}>
      <FormControl fullWidth>
        <InputLabel id="new-order-status-label">
          Выберите новый статус заказа
        </InputLabel>
        <Select
          labelId="new-order-status-label"
          label="Выберите новый статус заказа"
          onChange={(e) => setNewOrderStatus(e.target.value)}
          value={newOrderStatus}
        >
          {getOrderStatuses().map((el, key) => (
            <MenuItem key={key} value={el[0]}>
              {el[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <LoadingButton
          disabled={!newOrderStatus}
          onClick={onSubmit}
          loading={btnLoading}
          variant="contained"
        >
          Восстановить
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default RestoreOrderModal;
