import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { isMobile } from "react-device-detect";
import { observer } from "mobx-react-lite";
import { $sidebar } from "../store/sidebar";
import { MenuItems } from "./menuItems";

const Sidebar = observer(({ window }) => {
  const drawerWidth = $sidebar.visible ? 260 : 0;
  const drawerOpen = $sidebar.visible;

  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      style={isMobile ? { position: "fixed" } : {}}
      component="nav"
      sx={{ flexShrink: 0, width: !matchUpMd ? drawerWidth : "auto" }}
    >
      <Drawer
        container={container}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={drawerOpen}
        onClose={() => $sidebar.toggle()}
        sx={{
          "& .MuiDrawer-paper": {
            zIndex: 100,
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            overflowX: "hidden",
            borderRight: "1px solid #E7E8EA",
            top: isMobile ? 0 : "64px",
            height: isMobile ? "100%" : "calc(100vh - 64px)",
            transition: "all .2s ease-in-out!important",
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <MenuItems />
      </Drawer>
    </Box>
  );
});

export default Sidebar;
