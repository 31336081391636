import React from "react";
import { Box } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { $products } from "store/tags/productsInTags";
import { LoadingButton } from "@mui/lab";

const DeleteProductFromTagModal = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);
  const product = $modals.params;

  const onSubmit = () => {
    setBtnLoading(true);
    $products
      .deleteProductFromTag(product.id)
      .then(() => $modals.close())
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal
      title="Удаление товара из группы"
      subheader={
        <>
          Вы действительно хотите удалить товар из группы <br />{" "}
          <b>{product.name}</b>?
        </>
      }
    >
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          loading={btnLoading}
          variant="contained"
          onClick={onSubmit}
        >
          Удалить
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default DeleteProductFromTagModal;
