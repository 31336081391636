import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { $sidebar } from "store/sidebar";
import { $permissions } from "store/permissions/permissions";
import { PermissionsTable } from "./table";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { appRoutes } from "routes";
import { Alert } from "@mui/material";

const Permissions = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Доступы в разделы");
    $permissions.fetchList();
  }, [$permissions.selectedPage]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <FormControl size="small" sx={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label">Раздел</InputLabel>
          <Select
            value={$permissions.selectedPage}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Раздел"
            onChange={(e) => $permissions.setPage(e.target.value)}
          >
            {Object.values(appRoutes).map((el, key) => (
              <MenuItem key={key} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          sx={{ width: "200px" }}
          variant="contained"
          onClick={() => $permissions.generateCatalogPermissions()}
        >
          Сгенерировать права
        </Button>
      </Box>

      <Alert sx={{ mb: 2 }} variant="outlined" severity="info">
        Доступы <strong>редактирования</strong> применяются только к тем
        разделам, в которых используется <strong>/v3/</strong> endpoint!
      </Alert>

      <PermissionsTable />
    </>
  );
});

export default Permissions;
