import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $suggestions } from "store/suggestions/suggestions";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";

const NewSuggestionModal = observer(() => {
  const [suggestion, setSuggestion] = useState("");
  const [category, setCategory] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    $suggestions
      .createSuggestion({ suggestion, category })
      .then(() => {
        $suggestions.fetchCategories();
        $modals.close();
      })
      .catch((err) => console.log(err.response))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Создание сообщения">
      <form onSubmit={onSubmit}>
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel id="category">Категория</InputLabel>
          <Select
            value={category}
            labelId="category"
            id="category"
            label="Категория"
            onChange={(e) => setCategory(e.target.value)}
          >
            {$suggestions.categories.map((el) => (
              <MenuItem value={el.id} key={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          onChange={(e) => setSuggestion(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Сообщение"
          variant="outlined"
        />

        <Box sx={{ textAlign: "right" }}>
          <LoadingButton loading={btnLoading} type="submit" variant="contained">
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default NewSuggestionModal;
