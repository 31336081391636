import { Box, IconButton } from "@mui/material";
import { $suggestions } from "store/suggestions/suggestions";
import Chip from "@mui/material/Chip";
import React, { useState } from "react";
import styled from "@emotion/styled";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { observer } from "mobx-react-lite";
import { $textarea } from "store/chat/textarea";
import { $chatOrders } from "store/chat/orders";

const SuggestionsContainer = styled.div`
  padding: 10px 10px 0 10px;
  transition: all 1s ease-in-out;
  border-top: 1px solid #e7e8ea;

  .categories-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .category-chip {
    cursor: pointer;
    color: #fff;
    margin-bottom: 8px;
    margin-right: 8px;

    &.first-name {
      border: 1px solid rgba(26, 34, 63, 0.45);
      box-sizing: border-box;
    }

    &:hover {
      background: #ffa400;
    }
  }
`;

const TextItem = styled.div`
  margin-bottom: 8px;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.08s ease-in-out;
  background: rgba(204, 204, 204, 0.3);
  color: #191919;

  &:hover {
    background: rgba(204, 204, 204, 0.5);
  }
`;

const ToggleButton = styled(IconButton)`
  position: absolute;
  margin-top: -46px;
  margin-left: 6px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  svg {
    color: #6ab9fc;
  }
`;

export const Suggestions = observer(() => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [visible, toggleVisible] = useState(false);

  const categoryClicked = (key) =>
    setSelectedKey(selectedKey === key ? null : key);

  const categoryMessages =
    selectedKey !== null ? $suggestions.categories[selectedKey].messages : null;

  const onTextClicked = (text) => {
    if ($textarea.value.length > 0) {
      $textarea.appendValueNoSpace(text);
    } else {
      $textarea.setValue(text);
    }
  };

  return (
    <>
      <ToggleButton onClick={() => toggleVisible(!visible)}>
        <TextsmsIcon />
      </ToggleButton>

      {visible && (
        <SuggestionsContainer>
          {categoryMessages && (
            <>
              {categoryMessages.map((el) => (
                <TextItem key={el.id} onClick={() => onTextClicked(el.text)}>
                  {el.text}
                </TextItem>
              ))}
              {selectedKey == 0 && (
                <TextItem
                  onClick={() =>
                    onTextClicked(
                      `${$chatOrders.user.name}, здравствуйте!`
                    )
                  }
                >
                  {$chatOrders.user.name}, здравствуйте!
                </TextItem>
              )}
            </>
          )}
          <Box className="categories-wrapper">
            {$suggestions.categories.map((el, key) => (
              <React.Fragment key={el.id}>
                <Chip
                  className="category-chip"
                  onClick={() => categoryClicked(key)}
                  sx={{
                    background: selectedKey === key ? "#ffa400" : "#91cafa",
                  }}
                  label={el.name}
                />
              </React.Fragment>
            ))}
          </Box>
        </SuggestionsContainer>
      )}
    </>
  );
});
