import React from "react";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { observer } from "mobx-react-lite";
import { $snackbar } from "store/snackbar";

export const RenderSnackbar = observer(() => {
  return (
    <Snackbar
      open={$snackbar.open}
      autoHideDuration={3000}
      onClose={() => $snackbar.close()}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => $snackbar.close()}
        severity={$snackbar.type}
        sx={{ width: "100%" }}
      >
        {$snackbar.label}
      </Alert>
    </Snackbar>
  );
});
