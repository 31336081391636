import {
  orderStatuses,
  paymentStatuses,
  paymentTypeStatuses,
} from "../../../../lib/orderStatuses";
import React from "react";
import styled from "@emotion/styled";
import { Chip } from "@mui/material";

const chipColors = {
  0: "error",
  1: "primary",
  2: "secondary",
  3: "primary",
  4: "primary",
  5: "success",
  6: "success",
  7: "secondary",
  8: "warning",
};

export const ChipStyled = styled(Chip)`
  margin-right: 6px;
  height: 18px;
  font-size: 12px;
  margin-top: -4px;
  font-weight: 400;
  margin-bottom: 8px;
  text-transform: lowercase;
  border-radius: 5px;

  * {
    padding: 0 6px;
  }
`;

export const PaymentTypeChip = ({ paymentType }) => {
  if (!paymentType) return null;
  return (
    <ChipStyled color={"secondary"} label={paymentTypeStatuses[paymentType]} />
  );
};

export const OrderStatusChip = ({ status }) => {
  return (
    <ChipStyled color={chipColors[status]} label={orderStatuses[status]} />
  );
};

export const PaymentStatusChip = ({ status, paymentType }) => {
  return paymentType === 0 ? null : (
    <ChipStyled label={paymentStatuses[status]} />
  );
};

export const EntityChip = () => {
  return (
    <ChipStyled
      sx={{
        background: "#0094ff",
        color: "#fff",
      }}
      label="юр. лицо"
    />
  );
};

export const DeliveryChip = () => {
  return (
    <ChipStyled
      sx={{
        background: "#ffc1fc",
      }}
      label="Доставка"
    />
  );
};
