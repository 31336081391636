import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { $sidebar } from "store/sidebar";

const PageNotFound = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Страница не найдена");
  }, []);

  return (
    <Container
      fixed
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: "6rem" }}>404</div>
      <Typography variant="h4" component="div" sx={{ mt: 6, mb: 2 }}>
        Страница не существует
      </Typography>
    </Container>
  );
});

export default PageNotFound;
