import {types} from "mobx-state-tree";

export const $modals = types
  .model({
    name: types.optional(types.maybeNull(types.string), null),
    params: types.frozen(null)
  })
  .actions((self) => ({
    show(name, params) {
      self.name = name
      self.params = params
    },
    close() {
      self.name = null
      self.params = null
    }
  }))
  .create();
