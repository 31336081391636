import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { $complaints } from "store/complaints";
import React from "react";
import { observer } from "mobx-react-lite";
import {
  parseIsoDateTime,
} from "lib/features";
import { InfiniteScroll } from "ui-components/scrollingTable";

const columns = [
  {
    id: "id",
    label: "ID покупателя",
  },
  {
    id: "text",
    label: "Текст жалобы",
  },
  {
    id: "date",
    label: "Дата",
  },
];

const ComplaintsTable = observer(() => {
  return (
    <InfiniteScroll
      onScroll={() => $complaints.paginate()}
      hasMore={$complaints.hasMore}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$complaints.complaintsMessages.length > 0 &&
            $complaints.complaintsMessages.map((message, key) => {
              return (
                <TableRow hover tabIndex={-1} key={key}>
                  <TableCell>
                    <a
                      style={{ textDecoration: "none" }}
                      href={`/chat?customer_id=${message.customer_id}`}
                      target="_blank"
                    >
                      {message.customer_id}
                    </a>
                  </TableCell>
                  <TableCell>{message.message}</TableCell>
                  <TableCell>{parseIsoDateTime(message.created_at)}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default ComplaintsTable;
