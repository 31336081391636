import { observer } from "mobx-react-lite";
import DatePicker from "@mui/lab/DatePicker";
import { $chatStats } from "../../store/chatStats/chatStats";
import { TextField } from "@mui/material";
import Chart from "react-apexcharts";
import { isMobile } from "react-device-detect";
import React from "react";

export const DayPieChart = observer(() => {
  const chart = {
    options: {
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "65%",
            background: "transparent",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                showAlways: false,
                label: "Все сообщения",
                fontSize: "20px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      title: {
        text: "% Соотношение версий клиентов",
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "16px",
          fontWeight: "normal",
          fontFamily: undefined,
          color: "#9699a2",
        },
      },
      labels: $chatStats.pieChart.labels,
      colors: ["#ffb255", "#5e35b1", "#a7ff4d", "#4BC0C0"],
      legend: {
        position: "bottom",
        labels: {
          colors: "#000",
        },
      },
    },
    series: $chatStats.pieChart.series,
  };

  return (
    <div className="chartWrapper">
      <div className="datepicker">
        <DatePicker
          clearText="Очистить"
          clearable
          disableFuture
          label="Дата"
          value={$chatStats.pieChartValue}
          onChange={(newValue) => $chatStats.fetchPerDay(newValue)}
          renderInput={(params) => <TextField name={"datefns"} {...params} />}
        />
      </div>
      <Chart
        options={chart.options}
        series={chart.series}
        type="donut"
        height={isMobile ? "100%" : "400px"}
      />
    </div>
  );
});
