import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $suggestions } from "store/suggestions/suggestions";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";

const EditSuggestionModal = observer(() => {
  const params = $modals.params;

  const [suggestion, setSuggestion] = useState(params.text);
  const [category, setCategory] = useState(params.suggestion_category_id);
  const [btnLoading, setBtnLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const id = params.id;
    $suggestions
      .editSuggestion({ suggestion, category, id })
      .then(() => {
        $suggestions.fetchCategories();
        $modals.close();
      })
      .catch((err) => console.log(err.response))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Редактирование сообщения">
      <form onSubmit={onSubmit}>
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel id="category">Категория</InputLabel>
          <Select
            value={category}
            labelId="category"
            id="category"
            label="Категория"
            onChange={(e) => setCategory(e.target.value)}
          >
            {$suggestions.categories.map((el) => (
              <MenuItem value={el.id} key={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          onChange={(e) => setSuggestion(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          value={suggestion}
          label="Сообщение"
          variant="outlined"
        />

        <Box sx={{ textAlign: "right" }}>
          <LoadingButton loading={btnLoading} variant="contained" type="submit">
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default EditSuggestionModal;
