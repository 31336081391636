import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box, Button, FormControlLabel, Stack, Switch } from "@mui/material";
import UsersTable from "./table";
import { debounce } from "lodash";
import { InputSearch } from "ui-components/inputSearch";
import { $modals } from "store/modals";
import { NEW_USER_MODAL } from "ui-components/modal/modals";
import { isMobile } from "react-device-detect";
import { $sidebar } from "store/sidebar";
import { $users } from "store/users/users";

const Users = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Пользователи");
    $users.fetchList();
  }, []);

  const handleSearch = debounce((e) => {
    $users.setSearchValue(e.target.value);
  }, 500);

  const createUserButton = (
    <Box sx={{ padding: isMobile ? "0 0 12px 0" : "7px 0 0 0" }}>
      <Button variant="contained" onClick={() => $modals.show(NEW_USER_MODAL)}>
        Создать пользователя
      </Button>
    </Box>
  );

  return (
    <>
      {isMobile && createUserButton}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Stack spacing={2} direction="row">
          <InputSearch
            onChange={handleSearch}
            placeholder="Номер телефона или имя"
          />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => $users.toggleGlobalSearch()}
                checked={$users.globalSearch}
              />
            }
            label="Поиск среди всех пользователей"
          />
        </Stack>
        {!isMobile && createUserButton}
      </Box>
      <UsersTable />
    </>
  );
});

export default Users;
