import React from "react";
import { Modal } from "ui-components/modal";
import { $modals } from "store/modals";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $roles } from "store/roles/roles";

const DeleteRoleModal = observer(() => {
  const role = $modals.params;
  const isDeleted = role.deleted_at;
  const [btnLoading, setBtnLoading] = useState(false);

  const restoreRole = () => {
    setBtnLoading(true);
    $roles
      .restoreRole(role.name)
      .then(() => {
        $roles.fetchRoles();
      })
      .finally(() => {
        setBtnLoading(false);
        $modals.close();
      });
  };

  const deleteRole = () => {
    setBtnLoading(true);
    $roles
      .deleteRole(role.name)
      .then(() => {
        $roles.fetchRoles();
      })
      .finally(() => {
        setBtnLoading(false);
        $modals.close();
      });
  };

  return (
    <Modal
      title={isDeleted ? "Восстановить" : "Удалить"}
      subheader={`Вы действительно хотите ${
        isDeleted ? "восстановить" : "удалить"
      } роль ${role.name}?`}
    >
      <LoadingButton
        sx={{ float: "right" }}
        loading={btnLoading}
        variant="contained"
        onClick={() => (isDeleted ? restoreRole() : deleteRole())}
      >
        {isDeleted ? "восстановить" : "удалить"}
      </LoadingButton>
    </Modal>
  );
});

export default DeleteRoleModal;
