import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { InfiniteScroll } from "ui-components/scrollingTable";
import { $notifications } from "../../store/notifications/notifications";
import {
  numberWithSpaces,
  parseIsoDateTime,
} from "lib/features";

const columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "created_by",
    label: "Кем создано",
  },
  {
    id: "title",
    label: "Заголовок",
  },
  {
    id: "message",
    label: "Сообщение",
  },
  {
    id: "model_type",
    label: "model_type",
  },
  {
    id: "model_id",
    label: "model_id",
  },
  {
    id: "count",
    label: "Отправлено",
  },
  {
    id: "created_at",
    label: "Дата отправки",
  },
];

const NotificationsTable = observer(() => {
  return (
    <InfiniteScroll
      onScroll={() => $notifications.paginate()}
      hasMore={$notifications.hasMore}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$notifications.list.map((notification, key) => (
            <TableRow hover tabIndex={-1} key={key}>
              <TableCell>{notification.id}</TableCell>
              <TableCell>{notification.created_by}</TableCell>
              <TableCell>{notification.title}</TableCell>
              <TableCell>{notification.body}</TableCell>
              <TableCell>{notification.model_type}</TableCell>
              <TableCell>{notification.model_type}</TableCell>
              <TableCell>{numberWithSpaces(notification.sent_count)}</TableCell>
              <TableCell>{parseIsoDateTime(notification.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});

export default NotificationsTable;
