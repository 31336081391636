import React from "react";
import { Box, TextField } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useEffect, useState } from "react";
import { $suggestions } from "store/suggestions/suggestions";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";

const EditCategoryModal = observer(() => {
  const category = $modals.params;

  const [name, setName] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setName(category.name);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    $suggestions
      .editCategory(name, category.id)
      .then(() => {
        $suggestions.fetchCategories();
        $modals.close();
      })
      .catch((err) => console.log(err.response))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Редактирование категории">
      <form onSubmit={onSubmit}>
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          fullWidth
          sx={{ mb: 3 }}
          label="Имя категории"
          variant="outlined"
        />

        <Box sx={{ textAlign: "right" }}>
          <LoadingButton type="submit" loading={btnLoading} variant="contained">
            Сохранить
          </LoadingButton>
        </Box>
      </form>
    </Modal>
  );
});

export default EditCategoryModal;
