import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { $sidebar } from "store/sidebar";
import { DebugComponent } from "../debugComponent";

const Dashboard = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Главная");
  }, []);

  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <Typography
        onClick={() => setVisible(!isVisible)}
        variant="h4"
        component="div"
        sx={{ mb: 2 }}
      >
        Home page
      </Typography>

      {isVisible && <DebugComponent />}
    </>
  );
});

export default Dashboard;
