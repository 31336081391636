import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { RolesTable } from "./table";
import { $modals } from "store/modals";
import { NEW_ROLE_MODAL } from "ui-components/modal/modals";
import { isMobile } from "react-device-detect";
import { $sidebar } from "store/sidebar";
import { $roles } from "store/roles/roles";

const Roles = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Роли");
    $roles.fetchRoles(null, 1);
  }, []);

  const createUserButton = (
    <Box sx={{ padding: isMobile ? "0 0 12px 0" : "7px 0 0 0" }}>
      <Button
        variant="contained"
        onClick={() => {
          $modals.show(NEW_ROLE_MODAL);
        }}
      >
        Создать роль
      </Button>
    </Box>
  );

  return (
    <>
      {isMobile && createUserButton}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        {!isMobile && createUserButton}
      </Box>
      <RolesTable />
    </>
  );
});

export default Roles;
