import React from "react";
import { Box, Button } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { $promo } from "store/promo";
import { observer } from "mobx-react-lite";

const DeletePromoModal = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);
  const id = $modals.params;
  const onSubmit = () => {
    setBtnLoading(true);
    $promo
      .deletePromo(id)
      .then(() => {
        $promo.fetchPromoList();
      })
      .finally(() => {
        setBtnLoading(false);
        $modals.close();
      });
  };

  return (
    <Modal
      title="Удаление каталога"
      subheader={<>Вы действительно хотите удалить каталог?</>}
    >
      <Box sx={{ textAlign: "right" }}>
        <Button disabled={btnLoading} variant="contained" onClick={onSubmit}>
          Удалить
        </Button>
      </Box>
    </Modal>
  );
});

export default DeletePromoModal;
