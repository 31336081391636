import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "api";
import { flow } from "mobx";
import { $spinner } from "../spinner";
import { union } from "lodash";

export const $users = types
  .model("User", {
    list: types.frozen([]),
    allUsersCount: types.optional(types.integer, 0),

    searchValue: types.optional(types.string, ""),
    globalSearch: types.optional(types.boolean, false),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setUsers([]);
      }

      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`users`, {
            params: {
              search: self.searchValue,
              limit: self.limit,
              offset: self.offset,
              ...(self.globalSearch && { global: self.globalSearch }),
            },
          })
          .then((res) => res.data);

        self.setHasMore(res.length >= self.limit);
        self.setUsers(type === "paginate" ? union(self.list, res) : res);
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    create: flow(function* (data) {
      try {
        yield api(v3Endpoint)
          .post(`users`, data)
          .then((res) => res.data);
      } catch (err) {
        $spinner.hide();
        throw err;
      }
    }),
    edit: flow(function* (data) {
      try {
        yield api(v3Endpoint)
          .put(`users/${data.id}`, data)
          .then((res) => res.data);
      } catch (err) {
        $spinner.hide();
        throw err;
      }
    }),
    delete: flow(function* (id) {
      try {
        yield api(v3Endpoint)
          .delete(`users/${id}`)
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    setUsers(data) {
      self.list = data;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchList("paginate");
    },
    setSearchValue(value) {
      self.searchValue = value;
      self.fetchList();
    },
    resetPaginate() {
      self.offset = 0;
    },
    toggleGlobalSearch() {
      self.globalSearch = !self.globalSearch;
      self.fetchList();
    },
  }))
  .create();
