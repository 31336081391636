import { types } from "mobx-state-tree";
import { api } from "../../api";
import { flow } from "mobx";
import { $spinner } from "../spinner";
import { union } from "lodash";

export const $products = types
  .model("productsInTag", {
    productsInTagList: types.frozen([]),
    currentTagId: types.union(types.integer, types.optional(types.string, "")),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchProductsInTagList: flow(function* (type) {
      if (type !== "paginate") {
        self.resetPaginate();
        self.setProductsInTagList([]);
      }
      try {
        $spinner.show();
        const res = yield api()
          .get(`admin/product-tag/${self.currentTagId}`, {
            params: {
              limit: self.limit,
              offset: self.offset,
            },
          })
          .then((res) => res.data);
        self.setHasMore(res.length >= self.limit);
        self.setProductsInTagList(
          type === "paginate" ? union(self.productsInTagList, res) : res
        );
      } catch (err) {
        console.error("fetch networks list: ", err);
        throw err;
      }
      $spinner.hide();
    }),
    addProductToTag: flow(function* (productBarcode) {
      try {
        yield api()
          .post(`admin/product-tag`, {
            barcode: productBarcode,
            tag_id: self.currentTagId,
          })
          .then((res) => res.data)
          .then(() => {
            self.fetchProductsInTagList(self.currentTagId);
          });
      } catch (err) {
        throw err;
      }
    }),
    deleteProductFromTag: flow(function* (productId) {
      try {
        yield api()
          .delete(`admin/product-tag`, {
            params: {
              product_id: productId,
              tag_id: self.currentTagId,
            },
          })
          .then((res) => res.data);

        let items = [...self.productsInTagList];
        self.setProductsInTagList(items.filter((el) => el.id !== productId));
      } catch (err) {
        throw err;
      }
    }),
    uploadBarcodesFile: flow(function* (data) {
      const formData = new FormData();
      formData.append("file", data);
      formData.append("tag_id", self.currentTagId);
      try {
        const res = yield api()
          .post(`admin/product-tag-upload`, formData)
          .then((res) => res.data);
        self.setProductsInTagList(res);
      } catch (err) {
        console.error("upload file: ", err);
        throw err;
      }
    }),
    downloadBarcodesFile: flow(function* (id) {
      yield api()
        .get(`admin/product-tags-csv/${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          let date = new Date().toLocaleDateString();
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `groups_${date}.csv`);
          document.body.appendChild(link);
          link.click();
        });
    }),
    setProductsInTagList(data) {
      self.productsInTagList = data;
    },
    setCurrentTagId(tag_id) {
      self.currentTagId = tag_id;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchProductsInTagList("paginate");
    },
    setLimit(value) {
      self.limit = value;
    },
    resetPaginate() {
      self.offset = 0;
    },
  }))
  .create();
