import { $user } from "./store/user";
import { $permissions } from "./store/permissions/permissions";
import "./pusher";
// import "./centrifugo";
import { $bitrix } from "./store/bitrix";
import jwt_decode from "jwt-decode";
import axios from "axios";

export const initRequests = async () => {
  const JWT = localStorage.getItem("jwtToken");
  if (JWT) {
    axios.defaults.headers.common.Authorization = `Bearer ${
      jwt_decode(JWT).jti
    }`;
    axios.defaults.headers.common.JWT = JWT;

    $bitrix.checkAuth();
    await $user.setIsAuthorized(true);
    await $permissions.initPermissions();
  }
};

initRequests();
