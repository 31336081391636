import { Button, TextField } from "@mui/material";
import {
  api,
  consultantEndpoint,
  noPrefixEndpoint,
  v1Endpoint,
  v2Endpoint,
  v3Endpoint,
  v3EndpointCommon,
} from "../api";
import React, { useState } from "react";
import iziToast from "izitoast";
import styled from "@emotion/styled";

const DIV = styled.div`
  margin-bottom: 30px;
  padding: 14px;
  border: 1px solid #424242;
  border-radius: 5px;
`;

export const DebugComponent = () => {
  const [message, setMessage] = useState("");

  const sendClicked = () => {
    api(v3EndpointCommon)
      .post(`consultant/messages`, {
        message: message,
      })
      .then((res) => res.data)
      .then(() => {
        iziToast.success({
          message: "Отправлено!",
        });
      });
  };

  const getMessagesClicked = () => {
    api(v3EndpointCommon)
      .get(`consultant/messages`)
      .then((res) => res.data);
  };

  return (
    <div>
      <DIV>
        Имитатор чата клиента
        <br />
        <br />
        <div>
          <div>
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Введите сообщение"
              type="text"
            />
          </div>
          <br />
          <Button onClick={sendClicked} variant="contained">
            отправить
          </Button>
        </div>
      </DIV>

      <DIV>
        Получить переписку для клиента (web, ios, android)
        <br />
        <br />
        <div>
          <Button onClick={getMessagesClicked} variant="contained">
            GET
          </Button>
        </div>
      </DIV>

      <DIV>
        <b>app endpoints:</b>
        <div style={{ margin: "4px 0", display: "flex" }}>
          <div style={{ width: "170px" }}>noPrefixEndpoint:</div>{" "}
          {noPrefixEndpoint}
        </div>
        <div style={{ margin: "4px 0", display: "flex" }}>
          <div style={{ width: "170px" }}>v1Endpoint:</div>
          {v1Endpoint}
        </div>
        <div style={{ margin: "4px 0", display: "flex" }}>
          <div style={{ width: "170px" }}>v2Endpoint:</div>
          {v2Endpoint}
        </div>
        <div style={{ margin: "4px 0", display: "flex" }}>
          <div style={{ width: "170px" }}>v3Endpoint:</div>
          {v3Endpoint}
        </div>
        <div style={{ margin: "4px 0", display: "flex" }}>
          <div style={{ width: "170px" }}>consultantEndpoint:</div>{" "}
          {consultantEndpoint}
        </div>
      </DIV>
    </div>
  );
};
