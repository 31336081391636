export const USER_STATUS_BLOCKED = 0;
export const USER_STATUS_ACTIVE = 1;
export const USER_STATUS_WAIT = 2;
export const USER_STATUS_DELETED = 3;
export const USER_STATUS_BLOCKED_PURCHASE = 4;

export const userStatusNames = {
  [USER_STATUS_BLOCKED]: "Заблокирован",
  [USER_STATUS_ACTIVE]: "Активен",
  [USER_STATUS_WAIT]: "Не заполнен профиль",
  [USER_STATUS_DELETED]: "Удален",
  [USER_STATUS_BLOCKED_PURCHASE]: "Заблокированы покупки",
};

export const getBlockLabel = (user) =>
  user && user.status === USER_STATUS_ACTIVE
    ? "Заблокировать"
    : "Разблокировать";
export const getIsBlocked = (user) =>
  user && user.status !== USER_STATUS_ACTIVE;

export const getDeleteLabel = (user) =>
  user && user.status === USER_STATUS_DELETED ? "Восстановить" : "Удалить";
export const getIsDeleted = (user) =>
  user && user.status === USER_STATUS_DELETED;
