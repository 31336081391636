import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { api, v3Endpoint } from "../../api";
import { $chatRooms } from "./rooms";
import { $user } from "../user";
import { $textarea } from "./textarea";

export const $draft = types
  .model({})
  .actions((self) => ({
    create: flow(function* (draft) {
      if (!$chatRooms.selectedCustomerId) return;
      if ($textarea.isEditMode) return;

      const customerId = $chatRooms.selectedCustomerId;

      try {
        yield api(v3Endpoint)
          .post(`consultant/${$chatRooms.selectedCustomerId}/draft`, {
            operator_id: $user.profile.user_id,
            message: draft,
          })
          .then((res) => res.data);

        self.appendDraftMessage(draft, customerId);
      } catch (err) {
        throw err;
      }
    }),
    appendDraftMessage(draft, customer_id) {
      $chatRooms.updateRoomProperty("draft", draft, customer_id);
    },
  }))
  .create();
