import { $chatRooms } from "store/chat/rooms";
import { Avatar, Badge, Box, Chip } from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import React, { useEffect } from "react";
import { differenceInDays, format, parseISO } from "date-fns";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";

const ChatItemCard = styled.div`
  display: flex;
  padding: 12px;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  width: 100%;
  position: relative;

  &.delivery {
    box-shadow: inset 5px 0 0 0 #ffcf00;
  }

  .delivery-container {
    background: #ffcf00;
    border-radius: 14px;
    height: 24px;
    padding: 0 8px;
  }

  .avatar {
    width: 50px;
    height: 50px;
  }

  .top {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    height: 30px;
    color: #2b2d33;
    display: flex;

    .first-name {
      flex: 1 1 auto;
      margin-right: 6px;
      padding-top: 5px;
      text-overflow: ellipsis;

      white-space: nowrap;
      overflow: hidden;
    }

    .unread-icon {
      position: relative;
      width: 25px;
      display: flex;
      justify-content: center;
      margin-right: 8px;

      .badge {
        margin-top: 14px;
      }
    }

    .report-icon {
      margin-right: 8px;
      padding-top: 2px;

      svg {
        width: 22px;
        color: #ffa500;
      }
    }
  }

  .time {
    padding-top: 6px;
    color: #8b8f94;
    font-size: 15px;
  }

  &.selected,
  &:hover:not(&.unread) {
    background: rgba(144, 202, 249, 0.25);
  }
`;

const PreviewText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .user-type-label {
    color: #337ab7;
    margin-right: 4px;
    font-size: 15px;
  }

  .message {
    color: #8b8f94;
    font-size: 15px;
  }

  .draft {
    color: #980101;
    margin-right: 4px;
    font-size: 15px;
  }
`;

export const printDateLabel = (createdAt) => {
  if (!createdAt) {
    return null;
  }
  const diffInDays = differenceInDays(new Date(), parseISO(createdAt));
  const displayFormat = diffInDays > 0 ? "dd.MM.yy" : "HH:mm";
  return format(parseISO(createdAt), displayFormat);
};

const printAuthorLabel = (operatorId) => {
  if (operatorId === 4) {
    return "1С";
  } else if (operatorId) {
    return "Оператор";
  } else {
    return "Покупатель";
  }
};

export const ChatListItem = observer(({ room, chatCardClicked }) => {
  const user = room.message.user;
  const lastMessage = room.message;

  if (!user) {
    return <></>;
  }

  const printPreviewText = (room) => {
    if (!lastMessage) return null;

    const userLabel = (
      <span className="user-type-label">
        {printAuthorLabel(lastMessage.operator_id)}
      </span>
    );

    if (room.draft) {
      return (
        <>
          <span className="draft">Черновик:</span>
          <span className="message">{room.draft}</span>
        </>
      );
    } else if (lastMessage.as_file) {
      return <span>{userLabel}файл</span>;
    } else if (lastMessage.attachment_url) {
      return <span>{userLabel}изображение</span>;
    } else if (lastMessage.message) {
      return (
        <span className="message">
          {userLabel}
          {lastMessage.message}
        </span>
      );
    }
  };

  const cardClassNames = `${
    $chatRooms.selectedCustomerId == user.id ? "selected" : ""
  }${room.in_delivery ? "delivery" : ""}`;

  return (
    <ChatItemCard
      onClick={() => chatCardClicked(user.id, room.draft)}
      className={cardClassNames}
    >
      {lastMessage.sent_from && (
        <Chip
          color="secondary"
          label={lastMessage.sent_from}
          size="small"
          sx={{ position: "absolute", left: 0, zIndex: 10 }}
        />
      )}
      <Avatar className="avatar" src={user.profile.avatar} />

      <div
        style={{
          paddingLeft: "16px",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", position: "relative" }}>
          <div className="top">
            <div className="first-name">{user.profile.name}</div>
            {room.is_important && (
              <div className="report-icon">
                <LocalFireDepartmentIcon />
              </div>
            )}

            {room.unread_messages_count > 0 && (
              <div className="unread-icon">
                <Badge
                  className="badge"
                  color="secondary"
                  badgeContent={room.unread_messages_count}
                  max={99}
                />
              </div>
            )}

            <div className="time">{printDateLabel(lastMessage.created_at)}</div>
          </div>
          <Box sx={{ ml: "auto" }} />
          {room.in_delivery && (
            <div className="delivery-container">
              <LocalShippingOutlinedIcon sx={{ color: "rgba(0,0,0,0.75)" }} />
            </div>
          )}
        </div>
        <PreviewText>{printPreviewText(room)}</PreviewText>
      </div>
    </ChatItemCard>
  );
});
