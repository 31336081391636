import React from "react";
import iosIcon from "./images/iosIcon.png";
import iphone1 from "./images/1.png";
import iphone2 from "./images/2.png";
import iphone3 from "./images/3.png";
import { useState } from "react";
import "./styles.scss";
import { getRandomInt } from "lib/features";

export const NotificationExamples = ({ header, text }) => {
  header = header.length === 0 ? "Заголовок" : header;
  text = text.length === 0 ? "Сообщение" : text;

  const iphones = [iphone1, iphone2, iphone3];

  const [bgKey, setBgKey] = useState(getRandomInt(iphones.length));

  const bgClick = () => {
    setBgKey(bgKey === iphones.length - 1 ? 0 : bgKey + 1);
  };

  return (
    <>
      <img className="previewLoader" src={iphone1} alt="" />
      <img className="previewLoader" src={iphone2} alt="" />
      <img className="previewLoader" src={iphone3} alt="" />

      <div className="iphone-wrapper" onClick={bgClick}>
        <img className="iphone-image" key={bgKey} src={iphones[bgKey]} alt="" />
        <div className="notification">
          <div className="wrapper">
            <img src={iosIcon} alt="" />
            <div>
              <div className="title">{header}</div>
              <div className="text">{text}</div>
            </div>
            <div className="now">сейчас</div>
          </div>
        </div>
      </div>
    </>
  );
};
