import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { $modals } from "store/modals";
import { DELETE_ROLE_MODAL, EDIT_ROLE_MODAL } from "ui-components/modal/modals";
import {
  InfiniteScroll,
} from "ui-components/scrollingTable";
import { numberWithSpaces } from "lib/features";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styled from "@emotion/styled";
import { $roles } from "store/roles/roles";

const columns = [
  {
    id: "id",
    label: "№",
  },
  {
    id: "name",
    label: "Роль",
  },
  {
    id: "description",
    label: "Описание",
  },
  {
    id: "createdAt",
    label: "Дата создания",
  },
  {
    id: "usersCount",
    label: "Пользователей",
  },
  {
    id: "actions",
    label: "Действия",
    align: "right",
  },
];

const TableRowSt = styled(TableRow)`
  &.deleted {
    td {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;

export const RolesTable = observer(() => {
  const deleteClicked = (role) => {
    $modals.show(DELETE_ROLE_MODAL, role);
  };

  const editClicked = (role) => {
    $modals.show(EDIT_ROLE_MODAL, role);
  };

  return (
    <InfiniteScroll hasMore={false}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {$roles.roles.map((role, key) => (
            <TableRowSt
              className={role.deleted_at ? "deleted" : ""}
              hover
              tabIndex={-1}
              key={key}
            >
              <TableCell>{key + 1}</TableCell>
              <TableCell>{role.name}</TableCell>
              <TableCell>{role.description ? role.description : "-"}</TableCell>
              <TableCell>{role.created_at}</TableCell>
              <TableCell>{numberWithSpaces(role.users_count)}</TableCell>
              <TableCell align="right">
                <Tooltip title="Редактировать">
                  <IconButton onClick={() => editClicked(role)}>
                    <EditOutlinedIcon color="info" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={role.deleted_at ? "Восстановить" : "Удалить"}>
                  <IconButton onClick={() => deleteClicked(role)}>
                    {role.deleted_at ? (
                      <RestoreFromTrashOutlinedIcon color="info" />
                    ) : (
                      <DeleteOutlineOutlinedIcon color="error" />
                    )}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRowSt>
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
});
