import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { $sidebar } from "store/sidebar";
import { ReviewCard } from "./card";
import "./card.scss";

const items = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
];

const Reviews = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Отзывы");
  }, []);

  return (
    <>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Отзывы
      </Typography>
      <div className="reviews-container">
        {items.map((review, key) => (
          <ReviewCard review={review} key={key} />
        ))}
      </div>
    </>
  );
});

export default Reviews;
