import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "api";
import { flow } from "mobx";
import { $geo } from "../geo/geo";
import { $store } from "../store/store";

export const $notificationForm = types
  .model({
    usersCount: types.frozen(null),
    selectedRegionIds: types.frozen([]),
    selectedCityIds: types.frozen([]),
    selectedStoreIds: types.frozen([]),
    header: types.optional(types.string, ""),
    text: types.optional(types.string, ""),
    filtersLoading: types.optional(types.boolean, false),
    calculateLoading: types.optional(types.boolean, false),
    dates: types.frozen([null, null]),
  })
  .views((self) => ({
    getFilteredCities() {
      if (self.selectedRegionIds.length > 0) {
        return $geo.cities.map((city) => {
          return self.selectedRegionIds.includes(city.geo_region_id);
        });
      } else {
        return $geo.cities;
      }
    },
  }))
  .actions((self) => ({
    getFilters: flow(function* () {
      try {
        self.setFiltersLoading(true);
        const res = yield api(v3Endpoint)
          .get("notifications/global/filters")
          .then((res) => res.data);
        self.setFiltersLoading(false);

        $store.setStores(res.stores);
        $geo.setRegions(res.regions);
        $geo.setCities(res.cities);
      } catch (err) {
        throw err;
      }
    }),
    calculateUsersCount: flow(function* () {
      try {
        self.setCalculateLoading(true);
        const res = yield api(v3Endpoint)
          .post("notifications/global/calculateUsersCount", {
            region_ids: self.selectedRegionIds,
            city_ids: self.selectedCityIds,
            store_ids: self.selectedStoreIds,
            dates: self.dates.filter((el) => el != null),
          })
          .then((res) => res.data)
          .finally(() => self.setCalculateLoading(false));

        self.setUsersCount(res);
      } catch (err) {
        throw err;
      }
    }),
    send: flow(function* () {
      try {
        return yield api(v3Endpoint)
          .post("notifications/global", {
            header: self.header,
            text: self.text,
            filters: {
              region_ids: self.selectedRegionIds,
              city_ids: self.selectedCityIds,
              store_ids: self.selectedStoreIds,
              dates: self.dates.filter((el) => el != null),
            },
          })
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    setUsersCount(value) {
      self.usersCount = value;
    },
    setSelectedRegionIds(value) {
      self.selectedRegionIds = value;
    },
    setSelectedCityIds(value) {
      self.selectedCityIds = value;
    },
    setSelectedStoreIds(value) {
      self.selectedStoreIds = value;
    },
    setStores(value) {
      self.stores = value;
    },
    setHeader(value) {
      self.header = value;
    },
    setText(value) {
      self.text = value;
    },
    setFiltersLoading(value) {
      self.filtersLoading = value;
    },
    setCalculateLoading(value) {
      self.calculateLoading = value;
    },
    setDates(dates) {
      self.dates = dates;
    },
  }))
  .create();
