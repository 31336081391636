import { Avatar, Badge, Slide, Tooltip } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import styled from "@emotion/styled";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { NotificationMessage } from "./notificationMessage";
import { SimpleMessage } from "./simpleMessage";
import { observer } from "mobx-react-lite";
import tbpLogo from "./tbp_logo.png";

const SpeechBubbleSt = styled.div`
  display: flex;
  padding: 10px 16px;

  .with-avatar-container {
    display: flex;
    flex: 1 1 auto;

    &.operator {
      justify-content: flex-end;
    }
  }

  .wrapper {
    min-width: 240px;
    max-width: 80%;

    .content {
      flex: 1 1 auto;
      position: relative;
      background: rgba(255, 191, 49, 0.26);
      cursor: pointer;
      padding: 8px 10px;
      border-radius: 8px;
      color: #424041;
      //transition: all 0.1s ease-in-out;

      &.operator {
        background: rgba(144, 202, 249, 0.26);
      }

      &.selected {
        backdrop-filter: brightness(0.75);
      }

      .unread-label {
        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        animation: fadeOut 0.5s;
        animation-delay: 2s;
        -webkit-animation-fill-mode: forwards;
      }

      .name-label {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
      }
    }

    .timestamp {
      font-size: 14px;
      color: #818383;
      padding: 4px;
      display: flex;
      justify-content: space-between;
    }

    .download-file {
      margin: 4px 0;
      background: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 5px;
      font-size: 14px;
      color: #1d629d;
      text-decoration: none;

      svg {
        width: 20px;
        color: #1d629d;
      }

      span {
        padding-top: 2px;
        padding-left: 4px;
      }
    }
  }

  .complaint-label {
    position: absolute;
    right: 2px;
    top: 0;
    margin: 2px;
    width: 24px;
    color: rgba(245, 2, 2, 0.65);
  }
`;

export const SpeechBubble = observer(
  ({ message, messageClicked, selectedMessage }) => {
    const isOperator = !!message.operator_id;
    const isNotification = !!message.notification;

    return (
      <Slide mountOnEnter unmountOnExit direction="up" in>
        <SpeechBubbleSt>
          <div
            className={`with-avatar-container ${isOperator ? "operator" : ""}`}
          >
            {!isOperator && (
              <Avatar
                sx={{
                  mr: 1,
                  boxShadow: `${
                    message.user.avatar
                      ? "0px 0px 0px 1px rgba(34, 60, 80, 0.5) inset"
                      : "none"
                  }`,
                }}
                alt="customer avatar"
                src={isNotification ? tbpLogo : message.user.avatar}
              />
            )}
            <div className="wrapper">
              <div
                className={`content ${isOperator ? "operator" : ""} ${
                  message.id === selectedMessage?.id ? "selected" : ""
                }`}
                onClick={() => !isNotification && messageClicked(message)}
              >
                {message.is_complaint && (
                  <Tooltip title="Жалоба" placement="right-start">
                    <ReportGmailerrorredOutlinedIcon className="complaint-label" />
                  </Tooltip>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="name-label">
                    {isNotification ? "Уведомление" : message.user.name}

                    {message.sent_from && (
                      <span style={{ marginLeft: "10px", color: "#673ab7" }}>
                        {message.sent_from}
                      </span>
                    )}
                  </div>
                  {!message.viewed_at && !isOperator && (
                    <Badge
                      className="unread-label"
                      color="secondary"
                      badgeContent=" "
                      variant="dot"
                    />
                  )}
                </div>
                {isNotification ? (
                  <NotificationMessage notification={message.notification} />
                ) : (
                  <SimpleMessage message={message} />
                )}
              </div>
              <div className="timestamp">
                <div>{format(parseISO(message.created_at), "dd.MM.yyyy")}</div>
                <div>{format(parseISO(message.created_at), "HH:mm:ss")}</div>
              </div>
            </div>
            {isOperator && (
              <Avatar
                sx={{
                  ml: 1,
                  boxShadow: `${
                    message.user.avatar
                      ? "0px 0px 0px 1px rgba(34, 60, 80, 0.5) inset"
                      : "none"
                  }`,
                }}
                src={message.user.avatar}
                alt="operator avatar"
              />
            )}
          </div>
        </SpeechBubbleSt>
      </Slide>
    );
  }
);
